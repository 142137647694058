import { Button, Checkbox, Col, Image, Input, Layout, Row, Spin, TablePaginationConfig } from 'antd';
import { SortOrder } from 'antd/lib/table/interface';
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../../components/Sidebar/Sidebar';
import SearchTable, { ColumnTypeStringKey } from '../../components/Table/SearchTable';
import Topbar from '../../components/Topbar/Topbar';
import KundenService from '../../services/KundenService';
import LookUpService from '../../services/LookUpService';
import { KundenView, LookupLists, Sorter } from '../../services/api-types';
import * as fmt from '../../utils/formatter';
import { errorMessage, handleBeforeunloadEvent, hasElems, isPresent } from '../../utils/ts_helpers';

// id of branche Banken/Sparkassen/bankähnliche Institutionen
const BRANCHE_ID_BANK = 8;

export const MergeTool = () => {

    window.removeEventListener('beforeunload', handleBeforeunloadEvent);

    dayjs.locale('de');
    const { Content } = Layout;
    const navigate = useNavigate();

    ////////////////////////////////////////////////////////////////////////
    // component states
    ////////////////////////////////////////////////////////////////////////

    // data states
    const [data, setData] = useState<KundenView[]>([]);
    const [lookup, setLookup] = useState(LookupLists.DEFAULT());
    const [representColumns, setRepresentColumns] = useState([
        'Vorname',
        'Name',
        'Kunde von',
        'Zusatzbezeichnung',
        'Rechtsform',
        'Branche',
        'Adresse',
        'blz',
        'bicswift',
        'Matching Score',
    ]);

    interface CheckboxProps {
        id: number;
        checked?: boolean;
        disabled?: boolean;
    }
    const [checkboxPropsList, setCheckPropsList] = useState<CheckboxProps[]>([]);

    // states of current filter
    const [params, setParams] = useState<{ [key: string]: string | string[] }>({});
    const onParamsChange = (key: string, value: string | string[] | undefined) => {
        setParams(prev => {
            let newParams = Object.assign({}, prev);
            if (isPresent(value)) {
                newParams[key] = value;
            } else {
                delete newParams[key];
            }
            return newParams;
        });
    };

    // loading state
    const [isLookUpLoading, setIsLookUpLoading] = useState(true);

    const loadData = async (params: { [key: string]: string | string[] }, columns: Array<string>, pagination: TablePaginationConfig, sorters: Sorter[], signal: AbortSignal) => {

        let result = await KundenService.globalSearch(params, columns, pagination, sorters, signal);
        const data = calculateMatchingScore(result.content, sorters.find(s => s.column === 'Matching Score')?.order);
        setCheckPropsList(data.map(k => {
            return { id: k.kundeId, checked: false, disabled: false }
        }));
        result.content = data;
        return result;
    }

    ////////////////////////////////////////////////////////////////////////
    // effects and handlers
    ////////////////////////////////////////////////////////////////////////

    useEffect(() => {
        LookUpService.getLookUp().then(response => {
            setLookup(response);
        }).catch(error => {
            errorMessage(error);
        }).finally(() => {
            setIsLookUpLoading(false);
        });
    }, []);

    const handleCheckboxChange = (id: number, checked: boolean) => {
        let newCheckboxPropsList = [...checkboxPropsList];
        const checkedEntry = newCheckboxPropsList.find(entry => entry.id === id);
        const customer = data.find(d => d.kundeId === id);
        if (checkedEntry !== undefined) {
            checkedEntry.checked = checked;
        }
        if (checkboxPropsList.filter(entry => entry.checked).length === 2) {
            newCheckboxPropsList.filter(entry => !entry.checked).forEach(entry => entry.disabled = true);
        } else {
            if (customer?.smartDifferent) {
                newCheckboxPropsList.filter(entry => data.filter(d => d.smartDifferent && d.kundeId !== checkedEntry?.id).map(d => d.kundeId).includes(entry.id)).forEach(entry => entry.disabled = checked);
            } else {
                newCheckboxPropsList.filter(entry => data.filter(d => d.smartInnovation && d.kundeId !== checkedEntry?.id).map(d => d.kundeId).includes(entry.id)).forEach(entry => entry.disabled = checked);
            }
        }
        setCheckPropsList(newCheckboxPropsList);
    };

    ////////////////////////////////////////////////////////////////////////
    // table columns configuration
    ////////////////////////////////////////////////////////////////////////

    const columns: ColumnTypeStringKey<KundenView>[] = [
        {
            title: 'Vorname',
            key: 'Vorname',
            dataIndex: 'vorname',
            sorter: { multiple: 3 },
            width: 150,
            children: [{
                dataIndex: 'vorname',
                key: 'vorname',
                title:
                    <Input placeholder='Filter Vorname'
                        value={params['vorname']}
                        onChange={e => onParamsChange('vorname', e.target.value)}
                    />,
            }],
        },
        {
            title: 'Name',
            sorter: { multiple: 3 },
            dataIndex: 'name',
            key: 'Name',
            width: 400,
            children: [{
                dataIndex: 'name',
                key: 'name',
                title:
                    <Input placeholder='Filter Name'
                        value={params['name']}
                        onChange={e => onParamsChange('name', e.target.value)}
                    />,
            }],
        },
        {
            title: 'Kunde von',
            key: 'Kunde von',
            children: [{
                dataIndex: 'kundeVon',
                key: 'kundeVon',
                title:
                    <Input
                        placeholder="Filter Kunde"
                        value={params['kundeVon']}
                        onChange={e => {
                            onParamsChange('kundeVon', e.target.value)
                        }}
                    />,
            }],
        },
        {
            title: 'Rechtsform',
            dataIndex: 'rechtsform',
            key: 'Rechtsform',
            width: 300,
            children: [{
                dataIndex: 'rechtsform',
                key: 'rechtsform',
                title:
                    <Input
                        placeholder='Filter Rechtsform'
                        value={params['rechtsform']}
                        onChange={e => onParamsChange('rechtsform', e.target.value)}
                    />,
            }],
        },
        {
            title: 'Branche',
            width: 400,
            key: 'Branche',
            children: [{
                dataIndex: 'branche',
                key: 'branche',
                title: <Input
                    placeholder='Filter Branche'
                    value={params['branche']}
                    onChange={e => onParamsChange('branche', e.target.value)}
                />,
            }],
        },
        {
            title: 'Adresse',
            width: 550,
            key: 'Adresse',
            dataIndex: 'adresse',
            sorter: { multiple: 3 },
            children: [{
                dataIndex: 'adresse',
                key: 'adresse',
                title:
                    <Input
                        placeholder='Filter Adresse'
                        value={params['adresse']}
                        onChange={e => onParamsChange('adresse', e.target.value)}>
                    </Input>,
            }],
        },
        {
            title: 'BLZ',
            width: 200,
            key: 'blz',
            dataIndex: 'blz',
            sorter: { multiple: 4 },
            children: [{
                dataIndex: 'blz',
                key: 'blz',
                title:
                    <Input
                        placeholder='Filter BLZ'
                        value={params['blz']}
                        onChange={e => onParamsChange('blz', e.target.value)}>
                    </Input>,
            }],
        },
        {
            title: 'BIC',
            width: 200,
            key: 'bicswift',
            dataIndex: 'bicswift',
            sorter: { multiple: 5 },
            children: [{
                dataIndex: 'bicswift',
                key: 'bicswift',
                title:
                    <Input
                        placeholder='Filter BIC'
                        value={params['bicswift']}
                        onChange={e => onParamsChange('bicswift', e.target.value)}>
                    </Input>,
            }],
        },
        {
            title: 'Matching Score',
            dataIndex: 'score',
            key: 'Matching Score',
            sorter: { multiple: 6 },
            children: [
                {
                    dataIndex: 'score',
                    key: 'score',
                    render: (value: KundenView['score']) => {
                        return fmt.score(value);
                    },
                }
            ],
        },
    ];

    ////////////////////////////////////////////////////////////////////////
    // render component
    ////////////////////////////////////////////////////////////////////////

    return (
        <Layout>
            <Topbar />
            <Layout>
                <Sidebar />
                <Content style={{
                    padding: 20,
                    boxShadow: '0px 0px 15px -10px rgba(0,0,0,0.75)',
                    margin: 20,
                }}>
                    {
                        isLookUpLoading ?
                            <Row align="middle" justify="center">
                                <Spin size="large" />
                            </Row> :
                            <Row gutter={[8, 8]} >
                                <Col span={3} offset={21}>
                                    <Button
                                        danger
                                        hidden={checkboxPropsList.filter(entry => entry.checked && !entry.disabled).length !== 2}
                                        onClick={() => {
                                            const customers = data.filter(k => checkboxPropsList.filter(entry => entry.checked && !entry.disabled).map(entry => entry.id).indexOf(k.kundeId) !== -1);
                                            const link = '/mergecustomers/' + customers.map(c => c.kundeId).join('/');
                                            navigate(link);
                                        }}
                                    >Zusammenführen</Button>
                                </Col>
                                <Col span={24}>
                                    <SearchTable<KundenView>
                                        bordered
                                        onRow={(record, index?: number) => ({
                                            style: {
                                                background: record.color,
                                            },
                                        })}
                                        globalSearch
                                        globalSearchPlaceHolder='Filter Kunden'
                                        columns={columns}
                                        params={params}
                                        onParamsChange={onParamsChange}
                                        data={data}
                                        setData={setData}
                                        request={loadData}
                                        representColumns={representColumns}
                                        setRepresentColumns={setRepresentColumns}
                                        handleEditRecord={(customer: KundenView) => window.open('/#/customer/' + customer.kundeId, '_self')}
                                        actionConfig={{
                                            title: 'Aktion',
                                            width: 105,
                                            key: 'Aktion',
                                            children: [
                                                {
                                                    key: 'action',
                                                    title:
                                                        <Row gutter={[8, 8]} wrap={false}>
                                                            <Col><Button title="zurücksetzen" type="default" onClick={() => setParams(prev => ({}))}><Image height={16} preview={false} src='/images/filter-remove-icon.svg'></Image></Button></Col>
                                                        </Row>,
                                                    render: (_: unknown, record: KundenView, index: number) => {
                                                        return (
                                                            <Row gutter={[8, 8]} wrap={false}>
                                                                <Col>
                                                                    <Checkbox value={record.kundeId}
                                                                        checked={checkboxPropsList.find(entry => entry.id === record.kundeId)?.checked || false}
                                                                        disabled={checkboxPropsList.find(entry => entry.id === record.kundeId)?.disabled || false}
                                                                        onChange={e => {
                                                                            handleCheckboxChange(record.kundeId, e.target.checked);
                                                                        }}></Checkbox>
                                                                </Col>
                                                            </Row>)
                                                    },
                                                }],
                                        }}
                                    ></SearchTable>
                                </Col>
                            </Row>
                    }
                </Content>
            </Layout>
        </Layout>
    )
}

const colors = [
    "rgb(114,229,239)",
    "rgb(52,241,153)",
    "rgb(149,215,141)",
    "rgb(181,246,66)",
    "rgb(141,188,249)",
    "rgb(242,231,33)",
    "rgb(251,165,92)",
    "rgb(238,227,169)",
    "rgb(54,229,21)"
];

const calculateMatchingScore = (data: KundenView[], scoreDirection?: SortOrder) => {
    let idList: number[] = [];
    let newData = [...data];
    let colorIndex = 0;

    newData.filter(d => d.brancheId === BRANCHE_ID_BANK).forEach(d => {
        if (d.score === undefined) {
            d.score = 0;
        }

        if (d.kundeId !== undefined && !idList.includes(d.kundeId)) {
            const candidatArray = newData
                .filter(d => d.brancheId === BRANCHE_ID_BANK)
                .filter(c => c !== d
                    && c.bicswift === d.bicswift
                    && c.blz === d.blz
                    && (d.smartDifferent ? c.smartInnovation : c.smartDifferent));
            if (hasElems(candidatArray)) {
                const candidat = candidatArray.find(c => c.name.toLowerCase() === d.name.toLowerCase());
                if (candidat !== undefined) {
                    if (d.kundeId !== undefined && !idList.includes(d.kundeId)) {
                        idList.push(d.kundeId);
                    }

                    if (candidat.kundeId !== undefined && !idList.includes(candidat.kundeId)) {
                        idList.push(candidat.kundeId);
                    }

                    let score = 0.75;

                    if ((d.vorname && candidat.vorname?.includes(d.vorname)) || (candidat.vorname && d.vorname?.includes(candidat.vorname)) || d.vorname === candidat.vorname) {
                        score += 0.25;
                    }

                    candidat.score = score;
                    d.score = score;

                    if (colorIndex < colors.length) {
                        d.color = colors[colorIndex++];
                        candidat.color = d.color;
                    }

                }
            }
        }
    });

    newData.filter(d => d.brancheId !== BRANCHE_ID_BANK).forEach(d => {
        if (d.score === undefined) {
            d.score = 0;
        }
        if (d.kundeId !== undefined && !idList.includes(d.kundeId)) {
            const candidatArray = newData
                .filter(d => d.brancheId !== BRANCHE_ID_BANK)
                .filter(c =>
                    c !== d
                    && (c.adresse.includes(d.adresse) || d.adresse.includes(c.adresse))
                    && (d.smartDifferent ? c.smartInnovation : c.smartDifferent));

            if (hasElems(candidatArray)) {
                const candidat = candidatArray.find(c => c.name.toLowerCase() === d.name.toLowerCase());
                if (candidat !== undefined) {
                    if (d.kundeId !== undefined && !idList.includes(d.kundeId)) {
                        idList.push(d.kundeId);
                    }

                    if (candidat.kundeId !== undefined && !idList.includes(candidat.kundeId)) {
                        idList.push(candidat.kundeId);
                    }

                    let score = 0.75;

                    if ((d.vorname && candidat.vorname?.includes(d.vorname)) || (candidat.vorname && d.vorname?.includes(candidat.vorname)) || d.vorname.toLowerCase() === candidat.vorname.toLowerCase()) {
                        score += 0.25;
                    }

                    candidat.score = score;
                    d.score = score;

                    if (colorIndex < colors.length) {
                        d.color = colors[colorIndex++];
                        candidat.color = d.color;
                    }
                }
            }
        }
    });

    if (scoreDirection === 'ascend') {
        newData = newData.sort((a, b) => { return a.score! - b.score!; });
    }

    if (scoreDirection === 'descend') {
        newData = newData.sort((a, b) => { return b.score! - a.score!; });
    }

    return newData;
}
