import { DeleteOutlined, EditOutlined, ExportOutlined, FileDoneOutlined, MenuOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, Dropdown, Image, Input, Layout, MenuProps, Modal, Row, message } from 'antd';
import { TablePaginationConfig } from 'antd/lib/table/interface';
import dayjs from "dayjs";
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../../components/Sidebar/Sidebar';
import SearchTable, { ColumnTypeStringKey } from '../../components/Table/SearchTable';
import Topbar from '../../components/Topbar/Topbar';
import AngebotService from '../../services/AngebotService';
import ZaehlerstanderfassungService from '../../services/ZaehlerstanderfassungService';
import { AngebotTreeDataType, AngebotView, Sorter, Versorgungssituation } from '../../services/api-types';
import * as fmt from '../../utils/formatter';
import { groupBy, handleErrorResponse, hasElems, isPresent } from '../../utils/ts_helpers';

const OfferList = () => {

    dayjs.locale('de');

    const { Content } = Layout;

    const navigate = useNavigate();

    const [alertOpen, setAlertOpen] = useState(false);

    const handleAlertClose = () => {
        setAlertOpen(false);
    };

    const [record, setRecord] = useState<AngebotTreeDataType>();

    const [data, setData] = useState<Array<AngebotTreeDataType>>([]);

    const [params, setParams] = useState<{ [key: string]: string | string[] }>({});

    const onParamsChange = (key: string, value: string | string[]): void => {
        setParams(prev => {
            let newParams = Object.assign({}, prev);
            newParams[key] = value;
            return newParams;
        });
    };

    const loadData = async (params: { [key: string]: string | string[] }, columns: Array<string>, pagination: TablePaginationConfig, sorters: Sorter[], signal: AbortSignal) => {
        const result = await AngebotService.getPageableOffers(params, columns, pagination, sorters, signal);
        const newResult = { ...result, content: result.content.map(r => getAngebotDataType(r)) };
        return newResult;
    }

    const getAngebotDataType = (angebot: AngebotView): AngebotTreeDataType => {
        let result: AngebotTreeDataType = {
            key: 'angebot' + angebot.id,
            angebotId: angebot.id,
            label: angebot.label,
            durchschnittenergiepreis: angebot.durchschnittenergiepreis,
            gesamtverbrauch: angebot.gesamtverbrauch,
            children: [...angebot.zaehlerstanderfassungen.map(z => ({ ...z, key: 'z' + z.zaehlerstanderfassungId }))],
        };
        return result;
    }

    const handleConfirmDelete = async (record?: AngebotTreeDataType) => {
        try {
            if (record?.angebotId?.toString().match(/[0-9]+/)) {
                await AngebotService.deleteAngebot(record.angebotId);
                setData(prev => {
                    return prev.filter(d => d.angebotId !== record.angebotId);
                });
                message.success('Das Angebot wurde erfolgreich gelösht.');
            } else {
                if (record?.zaehlerstanderfassungId?.toString().match(/[0-9]+/)) {
                    await ZaehlerstanderfassungService.deleteZaehlerstanderfassung(record?.zaehlerstanderfassungId);
                    message.success('Die Zählerstanderfassung wurde erfolgreich gelösht.');
                }
            }
        } catch (error) {
            handleErrorResponse(error);
        }
    };

    const getConfirmDeleteMessage = (record?: AngebotTreeDataType): string => {
        return record?.children ? 'Möchten Sie das Angebot ' + record.label + ' wirklich löschen?' : 'Möchten Sie die Zählerstanderfassung ' + record?.lieferstelle + ' wirklich löschen?';
    }

    const handleDelete = (record?: AngebotTreeDataType) => {
        setRecord(record);
        setAlertOpen(true);
    }

    const handleEdit = (record?: AngebotTreeDataType) => {
        if (record?.angebotId && !record.zaehlerstanderfassungId) {
            navigate('/meter/?angebot=' + record.angebotId);
        } else {
            if (record?.zaehlerstanderfassungId && !record?.angebotId) {
                navigate('/meter/' + record.zaehlerstanderfassungId);
            }
        }
    }

    const [representColumns, setRepresentColumns] = useState<Array<string>>(['Kunde', 'Energieart', 'Objekte', 'Ges. Verbrauch', 'Durchschn. Energiepreis',
        'Lieferstelle', 'Zählertyp', 'Aktueller Versorger', 'Benchmark Versorger', 'Lieferbeginn', 'Zählernummer', 'Benchmark Datenquelle', 'Verbrauch', 'Benchmark Energiepreis',]);

    const columnsConfig: Array<ColumnTypeStringKey<AngebotTreeDataType>> = [
        {
            title: 'Kunde',
            key: 'Kunde',
            sorter: { multiple: 1 },
            children: [{
                title:
                    <Input placeholder='Filter Kunde'
                        value={params['kunde']}
                        onChange={e => {
                            onParamsChange('kunde', e.target.value);
                        }}
                    />,
                render: (_, record: AngebotTreeDataType) => record.children?.at(0) ? record.children?.at(0)?.kunde : '',
            }],
        },
        {
            title: 'Energieart',
            key: 'Energieart',
            sorter: { multiple: 2 },
            children: [{
                title:
                    <Input placeholder='Filter Energieart'
                        value={params['energieart']}
                        onChange={e => {
                            onParamsChange('energieart', e.target.value);
                        }}
                    />,
                render: (_, record: AngebotTreeDataType) => {
                    if (hasElems(record.children)) {
                        return record.children?.at(0)?.energieart ?? 'unbekannt';
                    } else {
                        return record.energieart ?? 'unbekannt'
                    }
                },
            }],
        },
        {
            title: 'Objekte',
            key: 'Objekte',
            sorter: { multiple: 3 },
            children: [{
                dataIndex: 'profilId',
                title: <Input placeholder='Filter Profil'
                    value={params['profil']}
                    onChange={e => {
                        onParamsChange('profil', e.target.value);
                    }}
                />,
                render: (_, record: AngebotTreeDataType) => {
                    if (record.children?.every(z => z.profil)) {
                        const grouped: Record<string, AngebotTreeDataType[]> = groupBy(record.children, 'profil');
                        const label = Object.keys(grouped).map(key => grouped[key].length + 'x' + key).join(' ');
                        return label;
                    }
                    return '';
                }
            }],
        },
        {
            title: 'Ges. Verbrauch',
            key: 'Ges. Verbrauch',
            sorter: { multiple: 4 },
            children: [{
                dataIndex: 'gesamtverbrauch',
                title: <Input placeholder='Filter Ges. Verbrauch'
                    value={params['gesamtverbrauch']}
                    onChange={e => {
                        onParamsChange('gesamtverbrauch', e.target.value);
                    }}
                />,
                render: value => value && !isNaN(value) ? fmt.deutschNumberFormat(value) + ' kWh p.a.' : ''
            }],
        },
        {
            title: 'Durchschn. Energiepreis',
            key: 'Durchschn. Energiepreis',
            sorter: { multiple: 5 },
            children: [{
                dataIndex: 'durchschnittenergiepreis',
                title: <Input placeholder='Filter Durchschn. Energiepreis'
                    value={params['durchschnittenergiepreis']}
                    onChange={e => {
                        onParamsChange('durchschnittenergiepreis', e.target.value);
                    }}
                />,
                render: value => value && !isNaN(value) ? fmt.deutschNumberFormat(value) + ' ct/kWh' : ''
            }],
        },
        {
            title: 'Lieferstelle',
            key: 'Lieferstelle',
            sorter: { multiple: 6 },
            children: [{
                dataIndex: 'lieferstelle',
                title: <Input placeholder='Filter Lieferstelle'
                    value={params['lieferstelle']}
                    onChange={e => {
                        onParamsChange('lieferstelle', e.target.value);
                    }}
                />,
                render: value => value ?? ''
            }],
        },
        {
            title: 'Zählertyp',
            key: 'Zählertyp',
            sorter: { multiple: 7 },
            children: [{
                dataIndex: 'profil',
                title: <Input placeholder='Filter Zählertyp'
                    value={params['profil']}
                    onChange={e => {
                        onParamsChange('profil', e.target.value);
                    }}
                />,
                render: value => value ?? ''
            }],
        },
        {
            title: 'Aktueller Versorger',
            key: 'Aktueller Versorger',
            sorter: { multiple: 8 },
            children: [{
                dataIndex: 'oversorger',
                title: <Input placeholder='Filter Aktueller Versorger'
                    value={params['oversorger']}
                    onChange={e => {
                        onParamsChange('oversorger', e.target.value);
                    }}
                />,
                render: value => value ?? ''
            }],
        },
        {
            title: 'Lieferbeginn',
            key: 'Lieferbeginn',
            sorter: { multiple: 9 },
            children: [{
                dataIndex: 'lieferbeginn',
                title: <Input placeholder='Filter Lieferbeginn'
                    value={params['lieferbeginn']}
                    onChange={e => {
                        onParamsChange('lieferbeginn', e.target.value);
                    }}
                />,
                render: value => value ?? ''
            }],
        },
        {
            title: 'Zählernummer',
            key: 'Zählernummer',
            sorter: { multiple: 10 },
            children: [{
                dataIndex: 'zaehlernummer',
                title: <Input placeholder='Filter Zählernummer'
                    value={params['zaehlernummer']}
                    onChange={e => {
                        onParamsChange('zaehlernummer', e.target.value);
                    }}
                />,
                render: value => value ?? ''
            }],
        },
        {
            title: 'Verbrauch',
            key: 'Verbrauch',
            sorter: { multiple: 11 },
            children: [{
                dataIndex: 'verbrauch',
                title: <Input placeholder='Filter Verbrauch'
                    value={params['verbrauch']}
                    onChange={e => {
                        onParamsChange('verbrauch', e.target.value);
                    }}
                />,
                render: value => value && !isNaN(value) ? fmt.deutschNumberFormat(value) + ' kWh p.a.' : ''
            }],
        },
        {
            title: 'Benchmark Versorger',
            key: 'Benchmark Versorger',
            sorter: { multiple: 12 },
            children: [{
                dataIndex: 'versorger',
                title: <Input placeholder='Filter Benchmark Versorger'
                    value={params['versorger']}
                    onChange={e => {
                        onParamsChange('versorger', e.target.value);
                    }}
                />,
                render: value => value ?? ''
            }],
        },
        {
            title: 'Benchmark Energiepreis',
            key: 'Benchmark Energiepreis',
            sorter: { multiple: 13 },
            children: [{
                dataIndex: 'energiepreis',
                title: <Input placeholder='Filter Benchmark Energiepreis'
                    value={params['energiepreis']}
                    onChange={e => {
                        onParamsChange('energiepreis', e.target.value);
                    }}
                />,
                render: value => value && !isNaN(value) ? fmt.deutschNumberFormat(fmt.round(value, 3)) + ' Cent/kWh' : ''
            }],
        },
        {
            title: 'Benchmark Datenquelle',
            key: 'Benchmark Datenquelle',
            sorter: { multiple: 14 },
            children: [{
                dataIndex: 'datenquelle',
                title: <Input placeholder='Filter Datenquelle'
                    value={params['datenquelle']}
                    onChange={e => {
                        onParamsChange('datenquelle', e.target.value);
                    }}
                />,
                render: value => value ?? ''
            }],
        },
    ]

    const [representedColumnsDropdownOpen, setRepresentedColumnsDropdownOpen] = useState(false);
    const [exportList, setExportList] = useState<Array<AngebotTreeDataType>>([]);
    const menuProps: MenuProps = {
        selectedKeys: representColumns,
        onSelect: ({ selectedKeys }) => setRepresentColumns(selectedKeys),
        onDeselect: ({ selectedKeys }) => setRepresentColumns(selectedKeys),
        items: columnsConfig.map(c => ({ key: c.key, label: c.key })),
        selectable: true,
        multiple: true,
    };

    const exportData = (list: AngebotTreeDataType[]) => {
        return list.flatMap(a => a.children).map(zaehlerstanderfassung => {
            return [zaehlerstanderfassung?.kunde,
            zaehlerstanderfassung?.rstrasse,
            zaehlerstanderfassung?.rhausnr,
            zaehlerstanderfassung?.rplz,
            zaehlerstanderfassung?.rort,
                '',
            zaehlerstanderfassung?.lstrasse,
            zaehlerstanderfassung?.lhausnr,
            zaehlerstanderfassung?.lplz,
            zaehlerstanderfassung?.lort,
                '',
            zaehlerstanderfassung?.verbrauch,
                '',
            zaehlerstanderfassung?.lieferbeginn,
            zaehlerstanderfassung?.profil,
            zaehlerstanderfassung?.versorgungssituation === Versorgungssituation.NEUEINZUG ? Versorgungssituation.NEUEINZUG : zaehlerstanderfassung?.oversorger,
            zaehlerstanderfassung?.versorgungssituation === Versorgungssituation.NEUEINZUG ? '' : zaehlerstanderfassung?.kundenvertragsnummer,
            zaehlerstanderfassung?.zaehlernummer,
            zaehlerstanderfassung?.messlokation,
            zaehlerstanderfassung?.marktlokation,
            ].join(';')
        });
    };

    const headers = [
        'Kunde',
        'Rechnungsaddresse Straße',
        'Rechnungsaddresse Hausnr',
        'Rechnungsaddresse PLZ',
        'Rechnungsaddresse Ort',
        '',
        'Lieferstelle Straße',
        'Lieferstelle Hausnr',
        'Lieferstelle PLZ',
        'Lieferstelle Ort',
        '',
        'Verbrauch p.a.',
        '',
        'Lieferbeginn',
        'SLP/RLM',
        'Versorger',
        'Kundennummer',
        'Zählernummer',
        'Zählpunktbezeichnung',
        'Marktlokation',
    ];

    const generateCSV = () => {
        const separator = ';';
        const content = '\ufeff' + headers?.join(separator) + '\n'
            + (
                exportData ?
                    exportData(exportList).join('\n') : ''
            );
        const blob = new Blob([content], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', 'angebot_' + new Date().toLocaleDateString() + '_' + new Date().toLocaleTimeString() + '.csv');
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const exportKostenVergleich = async () => {
        const angebotIds = exportList.map(v => v.angebotId);
        angebotIds.filter(isPresent).map(async id => {
            try {
                const res = await AngebotService.kostenVergleichExport(id);
                const contentDisposition = res.headers['content-disposition'];
                const fileName = contentDisposition.split(';')[1].trim().split('=')[1].replace(/"/g, '');
            
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('href', url);
                link.setAttribute('download', fileName);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } catch (error) {
                handleErrorResponse(error);
            }
        });
    };

    return (
        <Layout>
            <Topbar></Topbar>
            <Layout>
                <Sidebar />
                <Content style={{
                    padding: 20,
                    boxShadow: '0px 0px 15px -10px rgba(0,0,0,0.75)',
                    margin: 20,
                }}>
                    <Row gutter={[8, 8]}>
                        <Modal
                            destroyOnClose={true}
                            open={alertOpen}
                            maskClosable={false}
                            okButtonProps={{ danger: true }}
                            title="Löschen bestätigen?"
                            onOk={() => {
                                handleConfirmDelete(record);
                                handleAlertClose();
                            }}
                            okText={'Bestätigen'}
                            onCancel={handleAlertClose}>
                            <p>{getConfirmDeleteMessage(record)}</p>
                        </Modal>
                        {/** Table for all the offers */}
                        <Col span={24}>
                            <SearchTable<AngebotTreeDataType>
                                bordered
                                globalSearch
                                globalSearchPlaceHolder={'Suchen nach Angebot'}
                                columns={columnsConfig}
                                toolbar={
                                    <Row gutter={[8, 8]} style={{ marginBottom: 5 }}>
                                        <Col span={21}>
                                            Neues Angebot anlegen: <Button ghost type="primary" href={"/#/meter"} ><FileDoneOutlined></FileDoneOutlined></Button>
                                        </Col>
                                        <Col><Button title={'alle Filtern leeren'} onClick={() => setParams(() => ({}))} icon={<Image height={16} preview={false} src='/images/filter-remove-icon.svg'></Image>}></Button></Col>
                                        <Col>
                                            <Dropdown open={representedColumnsDropdownOpen} menu={menuProps} trigger={['click']}>
                                                <Button title={'angezeigte Spalten auswählen'} icon={<MenuOutlined></MenuOutlined>} onClick={e => { e.preventDefault(); setRepresentedColumnsDropdownOpen(!representedColumnsDropdownOpen); }}></Button>
                                            </Dropdown>
                                        </Col>
                                        <Col hidden={!exportList.length}><Button title={'Energieanfrage exportieren'} danger icon={<UploadOutlined />} onClick={() => generateCSV()}></Button></Col>
                                        <Col hidden={!exportList.length}><Button title={'Kostenvergleich exportieren'} style={{ color: 'orangered', border: '1px solid orangered' }} icon={<ExportOutlined />} onClick={() => exportKostenVergleich()}></Button></Col>
                                    </Row>
                                }
                                actionConfig={
                                    {
                                        title: 'Aktion',
                                        key: 'Aktion',
                                        children: [{
                                            render: (_, record: AngebotTreeDataType) => {
                                                return <Row justify={'center'} align={'middle'} gutter={[8, 8]} wrap={false}>
                                                    <Col><Button title="bearbeiten" type="primary" ghost icon={record.angebotId ? <PlusOutlined /> : <EditOutlined />} onClick={() => handleEdit(record)} /></Col>
                                                    <Col><Button title="löschen" danger icon={<DeleteOutlined />} onClick={() => handleDelete(record)} /></Col>
                                                    <Col hidden={!record.children}><Checkbox onChange={e => {
                                                        if (e.target.checked) {
                                                            setExportList(prev => {
                                                                return [...prev, record];
                                                            });
                                                        } else {
                                                            setExportList(prev => {
                                                                return prev.filter(e => e !== record);
                                                            });
                                                        }
                                                    }}></Checkbox></Col>
                                                </Row>
                                            },
                                        }],
                                    }}
                                representColumns={representColumns}
                                setRepresentColumns={setRepresentColumns}
                                params={params}
                                onParamsChange={onParamsChange}
                                request={loadData}
                                handleEditRecord={handleEdit}
                                data={data}
                                setData={setData}
                            />
                        </Col>
                    </Row>
                </Content>
            </Layout>
        </Layout>
    )
}

export default OfferList