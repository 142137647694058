import { Affix, Col, DatePicker, Input, Layout, message, Row, Select, Table } from 'antd';
import locale from 'antd/es/date-picker/locale/de_DE';
import { FilterValue, SorterResult, TableCurrentDataSource, TablePaginationConfig } from 'antd/lib/table/interface';
import axios from 'axios';
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import { useCallback, useEffect, useState } from 'react';
import Sidebar from '../../components/Sidebar/Sidebar';
import Topbar from '../../components/Topbar/Topbar';
import { ActionType, Audit, HistoryTableRequestConfig } from '../../services/api-types';
import HistoryService from '../../services/HistoryService';
import { handleBeforeunloadEvent, hasText } from '../../utils/ts_helpers';
import './History.css';

const History = () => {

  window.removeEventListener('beforeunload', handleBeforeunloadEvent);

  const { Option } = Select;

  const { Content } = Layout;

  const [data, setData] = useState<Audit[]>([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [total, setTotal] = useState(0);

  const [tableName, setTableName] = useState('');
  const [username, setUsername] = useState('');
  const [actionType, setActionType] = useState<ActionType>();
  const [start, setStart] = useState('');
  const [end, setEnd] = useState('');

  const [controller, setController] = useState<AbortController>();

  const buildTableRequestConfig = useCallback((): HistoryTableRequestConfig => {

    dayjs.locale('de');

    let config: HistoryTableRequestConfig = { currentPage: currentPage > 1 ? currentPage - 1 : 0, pageSize: pageSize, };
    if (hasText(tableName)) { config.tableName = tableName; } else { delete config.tableName; }
    if (hasText(actionType)) { config.actionType = actionType; } else { delete config.actionType; }
    if (hasText(username)) { config.username = username; } else { delete config.username; }
    if (hasText(start)) { config.start = dayjs(start, 'DD.MM.YYYY HH:mm:ss').format('YYYY-MM-DDTHH:mm:ss'); } else { delete config.start; }
    if (hasText(end)) { config.end = dayjs(end, 'DD.MM.YYYY HH:mm:ss').format('YYYY-MM-DDTHH:mm:ss'); } else { delete config.end; }
    return config;
  }, [
    currentPage,
    pageSize,
    tableName,
    actionType,
    username,
    start,
    end
  ]);

  const handleHistoryFilter = useCallback(async () => {
    if (controller) { controller.abort(); }
    const config = buildTableRequestConfig();
    const newController = new AbortController();
    setController(newController);
    try {
      const response = await HistoryService.getPage(config, newController.signal);
      setData(response.content);
      setTotal(response.totalElements);
      setCurrentPage(response.pageable.pageNumber + 1);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.warn(`${error}`);
      } else {
        message.error(`Error: ${error}`);
      }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ buildTableRequestConfig ]);

  useEffect(() => {
    handleHistoryFilter().catch(e => console.error(`should not happen: ${e}`, e));
  }, [
    handleHistoryFilter
  ]);

  const handleTableChange = (pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: SorterResult<Audit> | SorterResult<Audit>[], extra: TableCurrentDataSource<Audit>) => {
    if (controller) { controller.abort(); }
    if (extra.action !== "paginate" && extra.action !== "sort") {
      handleHistoryFilter();
    } else {
      let config = buildTableRequestConfig();
      config.currentPage = (pagination.current! - 1);
      config.pageSize = (pagination.pageSize!);
      const newController = new AbortController();
      setController(newController);
      setPageSize(pagination.pageSize!);
      HistoryService.getPage(config, newController.signal).then(response => {
        setData(response.content);
        setTotal(response.totalElements);
        if (pagination.current! > response.totalPages) {
          setCurrentPage(response.totalPages);
        } else {
          setCurrentPage(response.pageable.pageNumber + 1);
        }
      }).catch(error => {
        if (axios.isCancel(error)) {
          console.warn(`${error}`);
        } else {
          message.error(`Error: ${error}`);
        }
      });
    }
  };

  const columns = [
    {
      title: 'Tabelle',
      children: [{
        key: 'tableName',
        title: <Input placeholder='Filter Tabelle'
          value={tableName}
          onChange={e => setTableName(e.target.value)}
        />,
        dataIndex: 'tableName',
      }],
    },
    {
      title: 'Benutzer',
      children: [{
        key: 'username',
        title: <Input placeholder='Filter Benutzer'
          value={username}
          onChange={e => setUsername(e.target.value)}
        />,
        dataIndex: 'username',
      }],
    },
    {
      title: 'Timestamp',
      children: [{
        key: 'timestamp',
        title: <Row gutter={[8, 8]} align='bottom' style={{ margin: 5 }}>
          <Col>
            <DatePicker placeholder='Filtern Startdatum' style={{ width: '100%' }} showTime locale={locale} format={'DD.MM.YYYY HH:mm:ss'} onChange={(date, dateString) => { setStart(dateString); }} />
          </Col>
          <Col>
            <DatePicker placeholder='Filtern Enddatum' style={{ width: '100%' }} showTime locale={locale} format={'DD.MM.YYYY HH:mm:ss'} onChange={(date, dateString) => { setEnd(dateString); }} />
          </Col>
        </Row>,
        dataIndex: 'actionTimestamp',
        render: (value: Audit['actionTimestamp'], record: Audit, index: number) => {
          return dayjs(value).format('DD.MM.YYYY HH:mm:ss');
        },
      }],
    },
    {
      title: 'Aktion Type',
      children: [{
        key: 'actionType',
        dataIndex: 'actionType',
        title:
          <Row>
            <Col>
              <Select placeholder='Filtern Aktion Type' style={{ width: 100 }} value={actionType} onChange={setActionType}>
                <Option value=''>Filtern Aktion Type</Option>
                <Option value='i'>create</Option>
                <Option value='d'>delete</Option>
                <Option value='u'>update</Option>
              </Select>
            </Col>
          </Row>,
        render: (value: Audit['actionType'], record: Audit, index: number) => {
          switch (value) {
            case 'i':
              return 'create';
            case 'u':
              return 'update';
            case 'd':
              return 'delete';
          };
        },
      }],
    },
    {
      title: 'alte Werte',
      children: [{
        key: 'oldValues',
        dataIndex: 'oldValues',
        render: (oldValues: Audit['oldValues'], record: Audit) => <dl>{
          Object.entries(oldValues ?? {})
            .filter(([fName]) => record.actionType !== 'u' || record.updatedCols?.includes(fName))
            .map(([fName, fValue]) => <><dt>{fName}</dt><dd>{fValue}</dd></>)
        }</dl>,
      }],
    },
    {
      title: 'neue Werte',
      children: [{
        dataIndex: 'newValues',
        render: (newValues: Audit['newValues'], record: Audit) => <dl>{
          Object.entries(newValues ?? {})
            .filter(([fName]) => record.actionType !== 'u' || record.updatedCols?.includes(fName))
            .map(([fName, fValue]) => <><dt>{fName}</dt><dd>{fValue}</dd></>)
        }</dl>,
      }],
    },
    {
      title: 'updated Spalten',
      children: [{
        dataIndex: 'updatedCols'
      }],
    },
  ]

  return (
    <Layout>
      <Topbar></Topbar>
      <Layout>
        <Affix>
          <Sidebar />
        </Affix>
        <Content style={{
          padding: 20,
          boxShadow: '0px 0px 15px -10px rgba(0,0,0,0.75)',
          margin: 20,
        }}>
          <Row gutter={[8, 8]}>
            <Col span={24}>
              <Table
                bordered
                loading={false}
                showSorterTooltip={false}
                dataSource={data}
                columns={columns}
                onChange={handleTableChange}
                pagination={{ current: currentPage, pageSize: pageSize, total: total }} />
            </Col>
          </Row>
        </Content>
      </Layout>
    </Layout>
  )
}

export default History;