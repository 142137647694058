import { CheckCircleTwoTone, CloseCircleTwoTone, DeleteOutlined, EditOutlined, PlusOutlined, ProfileOutlined } from "@ant-design/icons";
import { Affix, Alert, Button, Col, Divider, Empty, Form, Input, InputNumber, Layout, Modal, Radio, Row, Select, Spin, Steps, Table, Tag } from "antd";
import 'antd/dist/reset.css';
import { ValidateStatus } from "antd/lib/form/FormItem";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useNavigate, useParams, unstable_usePrompt as usePrompt, useSearchParams } from "react-router-dom";
import SearchInput from "../../components/Form/SearchInput";
import EditNetzentgelte from "../../components/Offer/EditNetzentgelte";
import EditVertragInfo from "../../components/Offer/EditVertragInfo";
import Kuendigungsfrist from "../../components/Offer/Kuendigungsfrist";
import VertragspartnerWechselLieferbeginn from "../../components/Offer/VertragspartnerWechselLieferbeginn";
import Sidebar from "../../components/Sidebar/Sidebar";
import Topbar from "../../components/Topbar/Topbar";
import AngebotService from "../../services/AngebotService";
import KundenService from "../../services/KundenService";
import LookUpService from "../../services/LookUpService";
import { calcEnergieKosten, calcEnergiePreis, calcFixKosten, calcGesamtKosten, calcVariableKosten } from "../../services/RLMService";
import ZaehlerstanderfassungService from "../../services/ZaehlerstanderfassungService";
import { Address, Angebot, Energieart, KuendungsfristTyp, Kunde, KundenAdresse, Lieferstelle, LookupLists, Netzentgelte, Versorgungssituation, VertragsKost, Zaehlerstand, Zaehlerstanderfassung } from "../../services/api-types";
import * as fmt from "../../utils/formatter";
import { compareByKey, equalByProps, errorMessage, handleBeforeunloadEvent, handleErrorResponse, hasElems, hasText, isPresent, numberOrZero } from "../../utils/ts_helpers";
import { AddressType } from "../Customer/Customer";

const isAddressEqual = equalByProps<Address>;

// if von Branche "EVUs Energie und Umwelt"
const BRANCHE_ID_EVU = 28;

const MeterReading = () => {

    dayjs.locale('de');

    window.addEventListener('beforeunload', handleBeforeunloadEvent);

    const navigate = useNavigate();
    const { id } = useParams();

    const [addressList, setAddressList] = useState<Address[]>([]);

    const [zaehlerstanderfassung, setZaehlerstanderfassung] = useState<Zaehlerstanderfassung>({ lieferstelle: Lieferstelle.DEFAULT(), zaehlerstandList: [], netzentgeltList: [], vertragsKostList: [] });

    const addBillingAddress = (raddress?: KundenAdresse) => {
        setAddressList(prev => {
            if (raddress) {
                const address: Address = {
                    strasse: raddress.strasse,
                    hausnr: raddress.hausnr,
                    plz: raddress.plz,
                    ort: raddress.ort,
                    ortsteil: raddress.ortsteil,
                    land: raddress.land,
                };
                return !prev.some(a => isAddressEqual(a, address)) ? [...prev, address] : prev;
            }
            return prev;
        });
    };

    useEffect(() => {
        const loadZaehlerstanderfassung = async (id: string) => {
            try {
                const zaehlerstanderfassung = await ZaehlerstanderfassungService.getZaehlerstanderfassung(id);
                let newZaehlerstanderfassung = Object.assign({}, zaehlerstanderfassung);
                newZaehlerstanderfassung.zaehlerstandList = newZaehlerstanderfassung.zaehlerstandList.sort(compareByKey(v => v.id.datum!));
                setZaehlerstanderfassung(newZaehlerstanderfassung);
                addBillingAddress(zaehlerstanderfassung.kunde?.adressen?.find(a => a.adressart === AddressType.RECHNUNG));
                setCurrent(5);
            } catch (error) {
                errorMessage(error);
            }
        };
        if (id?.match(/[0-9]+/) && zaehlerstanderfassung.id?.toString() !== id) {
            loadZaehlerstanderfassung(id);
        }
    }, [id]);

    const [searchParams] = useSearchParams();

    const [isNew, setIsNew] = useState(true);

    const [angebot, setAngebot] = useState<Angebot>();

    useEffect(() => {
        const loadData = async (angebot: string) => {
            try {
                const res = await AngebotService.getAngebot(angebot);
                setAngebot(res);
                const kunde = res.zaehlerstanderfassungen.at(0)?.kunde;
                if (kunde) {
                    setZaehlerstanderfassung(prev => {
                        return { ...prev, kunde: kunde };
                    });

                    addBillingAddress(kunde.adressen?.find(a => a.adressart === AddressType.RECHNUNG));
                }

                if (res.zaehlerstanderfassungen.at(0)) {
                    setZaehlerstanderfassung(prev => {
                        let newValue = { ...prev };
                        newValue.lieferstelle.energieartId = res.zaehlerstanderfassungen.at(0)?.lieferstelle.energieartId;
                        return newValue;
                    });
                }

                setAddressList(prev => {
                    const newlist = [...prev];
                    res.zaehlerstanderfassungen.forEach(z => {
                        const address: Address = {
                            strasse: z.lieferstelle.strasse,
                            hausnr: z.lieferstelle.hausnr,
                            plz: z.lieferstelle.plz,
                            ort: z.lieferstelle.ort,
                            ortsteil: z.lieferstelle.ortsteil,
                            land: z.lieferstelle.land,
                        };
                        if (!newlist.some(a => isAddressEqual(a, address))) {
                            newlist.push(address);
                        }
                    });
                    return newlist;
                });
            } catch (error) {
                errorMessage(error);
            }
        }
        const angebot = searchParams.get('angebot');
        if (angebot) {
            setIsNew(false);
            loadData(angebot);
        }
    }, [searchParams.get('angebot')]);

    const [notSaved, setNotSaved] = useState(true);

    const [errors, setErrors] = useState<string[]>([]);

    const [messages, setMessages] = useState<string[]>([]);

    const { Step } = Steps;

    const { Content } = Layout;

    const { Option } = Select;

    const [current, setCurrent] = useState(0);

    const next = () => {
        setCurrent(prev => prev + 1);
        setView(prev => (parseInt(prev) + 1).toString());
    };

    const prev = () => {
        setCurrent(prev => prev - 1);
        setView(prev => (parseInt(prev) - 1).toString());
    };

    const [lookup, setLookup] = useState<LookupLists>(LookupLists.DEFAULT());

    useEffect(() => {
        const loadLookup = async () => {
            try {
                const result = await LookUpService.getLookUp();
                setLookup(result);
            } catch (error) {
                handleErrorResponse(error);
            }
        };

        loadLookup();

    }, []);

    const handleLieferstelleChange = <K extends keyof Lieferstelle>(prop: K, value: Lieferstelle[K] | null) => {
        setZaehlerstanderfassung(prev => {
            let o = Object.assign({}, prev);
            if (value === null) {
                delete o.lieferstelle[prop];
            } else {
                o.lieferstelle[prop] = value;
            }
            return o;
        });
    };

    const [meloStatus, setMeloStatus] = useState<ValidateStatus>("");
    const [meloMessage, setMeloMessage] = useState("");

    useEffect(() => {
        const melo = zaehlerstanderfassung.lieferstelle.messlokation;
        if (melo !== undefined) {
            const [meloStatus, meloMessage] = validateMelo(
                melo,
                zaehlerstanderfassung.lieferstelle.plz ?? ''
            )
            setMeloStatus(meloStatus);
            setMeloMessage(meloMessage);
        }
    }, [zaehlerstanderfassung.lieferstelle.messlokation, zaehlerstanderfassung.lieferstelle.plz]);

    const [nsu, setNSU] = useState<Array<Netzentgelte>>([]);

    useEffect(() => {
        const loadNetzentgelte = async (plz: string, gas: boolean) => {
            try {
                const netzentgelte = await ZaehlerstanderfassungService.getNetzentgelteByPlz(plz, gas);
                setNSU(netzentgelte);
            } catch (error) {
                errorMessage(error);
            }
        }

        if (zaehlerstanderfassung.lieferstelle.plz?.length === 5) {
            loadNetzentgelte(zaehlerstanderfassung.lieferstelle.plz, zaehlerstanderfassung.lieferstelle.energieartId === Energieart.GAS);
        }

    }, [zaehlerstanderfassung.lieferstelle.plz, zaehlerstanderfassung.lieferstelle.energieartId]);

    useEffect(() => {
        const malo = zaehlerstanderfassung.lieferstelle.marktlokation;
        if (malo !== undefined) {
            const [maloStatus, maloMessage] = validateMalo(malo);
            setMaloMessage(maloMessage);
            setMaloStatus(maloStatus);
        }
    }, [zaehlerstanderfassung.lieferstelle.marktlokation]);

    const handlePlzChange = (plz: string) => {
        if (plz.length && plz.length < 5) {
            setPlzHelp(5 - plz.length + ' Ziffern zu wenig.');
            setPlzStatus('error');
        } else {
            setPlzHelp('');
            setPlzStatus('success');
        }
        handleLieferstelleChange('plz', plz);
    };

    const [vertragInfoModalOpen, setVertragInfoModalOpen] = useState(false);
    const [currentVertragKost, setCurrentVertragKost] = useState<VertragsKost>(VertragsKost.DEFAULT());
    const [vertragkostIndex, setVertragkostIndex] = useState<number>();
    // states to compare the cost
    const [view, setView] = useState('0');

    useEffect(() => {
        document.getElementById(view)?.scrollIntoView({ behavior: "smooth", block: "center" });
    }, [view]);

    const openVertragInfoModal = () => {
        setVertragkostIndex(undefined);
        setVertragInfoModalOpen(true);
    };

    const saveVertragKost = () => {
        setZaehlerstanderfassung(prev => {
            let newZaehlerstanderfassung = Object.assign({}, prev);
            let newValue = Object.assign({}, currentVertragKost);
            const kosten = calcGesamtKosten(newValue, zaehlerstanderfassung);
            const energiekosten = calcEnergieKosten(newValue, newZaehlerstanderfassung);
            const energiepreis = calcEnergiePreis(newValue, newZaehlerstanderfassung);

            newValue.gesamtkosten = kosten;
            newValue.energiekosten = energiekosten;
            newValue.energiepreis = energiepreis;

            if (vertragkostIndex !== undefined) {
                newZaehlerstanderfassung.vertragsKostList.splice(vertragkostIndex, 1, newValue);
            } else {
                newZaehlerstanderfassung.vertragsKostList = [...newZaehlerstanderfassung.vertragsKostList, newValue];
            }

            return newZaehlerstanderfassung;
        });
        closeVertragInfoModal();
    };

    const editVertragKost = (vertragkost: VertragsKost, index: number) => {
        setVertragkostIndex(index);
        setCurrentVertragKost(vertragkost);
        setVertragInfoModalOpen(true);
    };

    const deleteVertragKost = (vertragkost: VertragsKost) => {
        setZaehlerstanderfassung(() => {
            let newZaehlerstanderfassung = Object.assign({}, zaehlerstanderfassung);
            newZaehlerstanderfassung.vertragsKostList = newZaehlerstanderfassung.vertragsKostList?.filter(v => v !== vertragkost);
            return newZaehlerstanderfassung;
        });
    };

    const closeVertragInfoModal = () => {
        setCurrentVertragKost(VertragsKost.DEFAULT());
        setVertragInfoModalOpen(false);
    };

    const handleRelevantVertragKostChange = (vertragKost: VertragsKost) => {
        setZaehlerstanderfassung(prev => {
            let newZaehlerstanderfassung = Object.assign({}, prev);
            newZaehlerstanderfassung.vertragsKostList.forEach(v => {
                if (v === vertragKost) {
                    v.relevant = true;
                } else {
                    v.relevant = false;
                }
            });
            return newZaehlerstanderfassung;
        });
    };

    const [currentReading, setCurrentReading] = useState<Zaehlerstand>(Zaehlerstand.DEFAULT());

    const [readingsModalOpen, setReadingsModalOpen] = useState(false);

    const openReadingsModal = () => {
        if (hasElems(zaehlerstanderfassung.zaehlerstandList)) {
            setCurrentReading(() => {
                let newReading = Zaehlerstand.DEFAULT();
                newReading.zaehlernummer = zaehlerstanderfassung.zaehlerstandList?.at(zaehlerstanderfassung.zaehlerstandList?.length - 1)?.zaehlernummer;
                return newReading;
            });
        }
        setReadingIndex(undefined);
        setReadingsModalOpen(true);
    };

    const closeReadingsModal = () => {
        setReadingsModalOpen(false);
    };

    const handleReadingChange = <K extends keyof Zaehlerstand>(prop: K, value: Zaehlerstand[K] | null) => {
        setCurrentReading(prev => {
            let v = Object.assign({}, prev);
            if (value === null) {
                delete v[prop];
            } else {
                v[prop] = value;
            }
            return v;
        });
    };

    const [readingReady, setReadingReady] = useState(false);
    const [znStatus, setZnStatus] = useState<ValidateStatus>("");
    const [znMessage, setZnMessage] = useState("");
    const [readingErrorMessage, setReadingErrorMessage] = useState("");

    useEffect(() => {

        const ready = validateZN(currentReading, zaehlerstanderfassung.zaehlerstandList,
            setZnStatus,
            setZnMessage,
            setReadingErrorMessage
        )
        setReadingReady(ready);

    }, [currentReading, zaehlerstanderfassung.zaehlerstandList]);

    const [readingIndex, setReadingIndex] = useState<number>();

    const editReading = (reading: Zaehlerstand, index?: number) => {
        setCurrentReading(reading);
        setReadingIndex(index);
        setReadingsModalOpen(true);
    };

    const saveReading = () => {
        setZaehlerstanderfassung(prev => {
            let newZaehlerstanderfassung = Object.assign({}, prev);
            let newValue = Object.assign({}, currentReading);
            if (newValue.id.zaehlerstanderfassungId !== zaehlerstanderfassung.id) {
                newValue.id.zaehlerstanderfassungId = zaehlerstanderfassung.id;
            }
            if (readingIndex !== undefined) {
                newZaehlerstanderfassung.zaehlerstandList.splice(readingIndex, 1, newValue);
            } else {
                if (newZaehlerstanderfassung.zaehlerstandList.find(z => z.id.zaehlerstanderfassungId === newValue.id.zaehlerstanderfassungId && z.id.datum === newValue.id.datum)) {
                    newZaehlerstanderfassung.zaehlerstandList.splice(newZaehlerstanderfassung.zaehlerstandList.findIndex(z => z.id.zaehlerstanderfassungId === newValue.id.zaehlerstanderfassungId && z.id.datum === newValue.id.datum), 1, newValue);
                } else {
                    newZaehlerstanderfassung.zaehlerstandList = [...newZaehlerstanderfassung.zaehlerstandList, newValue];
                }
            }
            return newZaehlerstanderfassung;
        });
        setCurrentReading(Zaehlerstand.DEFAULT());
        closeReadingsModal();
    };

    const deleteReading = (reading: Zaehlerstand) => {
        setZaehlerstanderfassung(prev => {
            let newZaehlerstanderfassung = Object.assign({}, prev);
            newZaehlerstanderfassung.zaehlerstandList = newZaehlerstanderfassung.zaehlerstandList?.filter(r => r !== reading);
            return newZaehlerstanderfassung;
        });
    };

    const getPreProcessedData = () => {
        const etList = zaehlerstanderfassung.zaehlerstandList.filter(r => r.et).sort((a, b) => { if (a.id.datum && b.id.datum) { return new Date(a.id.datum).getTime() - new Date(b.id.datum).getTime(); } return 0; });
        const htntList = zaehlerstanderfassung.zaehlerstandList.filter(r => r.ht && r.nt).sort((a, b) => { if (a.id.datum && b.id.datum) { return new Date(a.id.datum).getTime() - new Date(b.id.datum).getTime(); } return 0; });

        const isGas = zaehlerstanderfassung.lieferstelle.energieartId === Energieart.GAS;

        const etPeriod = Math.abs(dayjs(etList.at(etList.length - 1)?.id.datum).diff(dayjs(etList.at(0)?.id.datum), 'days')) + 1;
        const htPeriod = Math.abs(dayjs(htntList.at(htntList.length - 1)?.id.datum).diff(dayjs(htntList.at(0)?.id.datum), 'days')) + 1;
        const ntPeriod = Math.abs(dayjs(htntList.at(htntList.length - 1)?.id.datum).diff(dayjs(htntList.at(0)?.id.datum), 'days')) + 1;

        if (isGas) {
            const et = Math.abs(numberOrZero(etList.at(etList.length - 1)?.et) - numberOrZero(etList.at(0)?.et));
            const yearlyEt = et / etPeriod * 365;
            return {
                et, etPeriod, yearlyEt,
            };
        }

        const et = Math.abs(numberOrZero(etList.at(etList.length - 1)?.et) - numberOrZero(etList.at(0)?.et)) * numberOrZero(zaehlerstanderfassung.lieferstelle.faktor);
        const ht = Math.abs(numberOrZero(htntList.at(htntList.length - 1)?.ht) - numberOrZero(htntList.at(0)?.ht)) * numberOrZero(zaehlerstanderfassung.lieferstelle.faktor);
        const nt = Math.abs(numberOrZero(htntList.at(htntList.length - 1)?.nt) - numberOrZero(htntList.at(0)?.nt)) * numberOrZero(zaehlerstanderfassung.lieferstelle.faktor);

        const yearlyEt = et / etPeriod * 365;
        const yearlyHt = ht / htPeriod * 365;
        const yearlyNt = nt / ntPeriod * 365;

        return {
            et, ht, nt, etPeriod, htPeriod, ntPeriod, yearlyEt, yearlyHt, yearlyNt
        };
    }

    const getGesamt = () => {
        let result = '';

        const data = getPreProcessedData();

        let needWarning = false;

        if (zaehlerstanderfassung.lieferstelle.energieartId === Energieart.GAS) {
            if (zaehlerstanderfassung.lieferstelle.verbrauch !== undefined
                && zaehlerstanderfassung.lieferstelle.verbrauch !== 0
                && data.et !== 0
                && zaehlerstanderfassung.lieferstelle.brennwert !== undefined
                && zaehlerstanderfassung.lieferstelle.zustandszahl !== undefined) {

                result += 'Gas ' + fmt.round(zaehlerstanderfassung.lieferstelle.verbrauch, 0) + ' kWh/Jahr'

                if (Math.abs((zaehlerstanderfassung.lieferstelle.verbrauch - data.et * zaehlerstanderfassung.lieferstelle.brennwert * zaehlerstanderfassung.lieferstelle.zustandszahl) / zaehlerstanderfassung.lieferstelle.verbrauch) > 0.2) {
                    needWarning = true;
                }
            }
        } else {
            if (zaehlerstanderfassung.lieferstelle.verbrauch !== undefined && zaehlerstanderfassung.lieferstelle.verbrauch !== 0 && data.et !== 0) {

                result += 'ET ' + fmt.round(zaehlerstanderfassung.lieferstelle.verbrauch, 0) + ' kWh/Jahr';

                if (Math.abs((zaehlerstanderfassung.lieferstelle.verbrauch - data.et) / zaehlerstanderfassung.lieferstelle.verbrauch) > 0.2) {
                    needWarning = true;
                }
            }
        }

        return <>
            {result}<Tag color="red" hidden={!needWarning}>Bitte den Jahresverbrauch überprüfen. Die Differenz zwischen Jahresverbrauch und Jahresprognose beträgt mehr als 20 %.</Tag>
        </>
            ;
    }

    const takeOverAddress = (value: string) => {
        const address = addressList.find(a => fmt.address(a) === value);
        if (address !== undefined) {
            setZaehlerstanderfassung(prev => {
                let zaehlerstanderfassung = Object.assign({}, prev);
                zaehlerstanderfassung.lieferstelle.strasse = address.strasse;
                zaehlerstanderfassung.lieferstelle.hausnr = address.hausnr;
                zaehlerstanderfassung.lieferstelle.plz = address.plz;
                zaehlerstanderfassung.lieferstelle.ort = address.ort;
                zaehlerstanderfassung.lieferstelle.ortsteil = address.ortsteil;
                zaehlerstanderfassung.lieferstelle.land = address.land;
                return zaehlerstanderfassung;
            });
        }
    }

    const [maloStatus, setMaloStatus] = useState<ValidateStatus>('');
    const [maloMessage, setMaloMessage] = useState('');

    const [plzStatus, setPlzStatus] = useState<ValidateStatus>('');
    const [plzHelp, setPlzHelp] = useState('');

    const [_versorgerList, setVersorgerList] = useState<Kunde[]>([]);
    useEffect(() => {

        const fetchVersorgerList = async () => {
            try {
                const result = await KundenService.findAllById(zaehlerstanderfassung.vertragsKostList.map(v => v.versorger?.id).filter(isPresent));
                setVersorgerList(result);
            } catch (error) {
                errorMessage(error);
            }
        }

        fetchVersorgerList();

    }, [zaehlerstanderfassung.vertragsKostList]);

    const [netzentgelteModalOpen, setNetzentgelteModalOpen] = useState(false);
    const [currentNetzentgelte, setCurrentNetzentgelte] = useState<Netzentgelte>(Netzentgelte.DEFAULT());
    const [netzentgelteIndex, setNetzentgelteIndex] = useState<number>();

    const openNetzentgelteModal = () => {
        setNetzentgelteModalOpen(true);
    };

    const editNetzentgelte = (netzentgelte: Netzentgelte, index: number) => {
        setCurrentNetzentgelte(netzentgelte);
        setNetzentgelteIndex(index);
        setNetzentgelteModalOpen(true);
    };

    const saveNetzentgelte = (netzentgelte: Netzentgelte) => {
        setZaehlerstanderfassung(prev => {
            let newZaehlerstanderfassung = Object.assign({}, prev);
            let newValue = Object.assign({}, netzentgelte);
            newValue.fixkosten = calcFixKosten(newValue);
            newValue.variablekosten = calcVariableKosten(newValue, zaehlerstanderfassung);
            newValue.gesamtkosten = fmt.round(newValue.fixkosten, 2) + fmt.round(newValue.variablekosten / 100, 2);
            if (newValue.id.zaehlerstanderfassungId !== zaehlerstanderfassung.id) {
                newValue.id.zaehlerstanderfassungId = zaehlerstanderfassung.id;
            }
            if (netzentgelteIndex !== undefined) {
                newZaehlerstanderfassung.netzentgeltList.splice(netzentgelteIndex, 1, newValue);
            } else {
                if (newZaehlerstanderfassung.netzentgeltList.find(n => n.id.zaehlerstanderfassungId === newValue.id.zaehlerstanderfassungId && n.id.preisstand === newValue.id.preisstand)) {
                    newZaehlerstanderfassung.netzentgeltList.splice(newZaehlerstanderfassung.netzentgeltList.findIndex(n => n.id.zaehlerstanderfassungId === newValue.id.zaehlerstanderfassungId && n.id.preisstand === newValue.id.preisstand), 1, newValue);
                } else {
                    newZaehlerstanderfassung.netzentgeltList = [...newZaehlerstanderfassung.netzentgeltList, newValue];
                }
            }

            newZaehlerstanderfassung.vertragsKostList.forEach((v, i) => {
                let newV: VertragsKost = { ...v };
                const kosten = calcGesamtKosten(newV, zaehlerstanderfassung);
                const energiekosten = calcEnergieKosten(newV, newZaehlerstanderfassung);
                const energiepreis = calcEnergiePreis(newV, newZaehlerstanderfassung);
                newV.gesamtkosten = kosten;
                newV.energiekosten = energiekosten;
                newV.energiepreis = energiepreis;
                newZaehlerstanderfassung.vertragsKostList.splice(i, 1, newV);
            });
            return newZaehlerstanderfassung;
        });
        closeNetzentgelteModal();
    };

    const deleteNetzentgelte = (netzentgelte: Netzentgelte) => {
        setZaehlerstanderfassung(prev => {
            let newZaehlerstanderfassung = Object.assign({}, prev);
            newZaehlerstanderfassung.netzentgeltList = newZaehlerstanderfassung.netzentgeltList.filter(n => n !== netzentgelte);
            return newZaehlerstanderfassung;
        });
    };

    const closeNetzentgelteModal = () => {
        setNetzentgelteModalOpen(false);
    };

    const [deleteMessage, setDeleteMessage] = useState('');
    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
    const [onDeleteOk, setOnDeleteOk] = useState(() => () => setConfirmDeleteOpen(false));
    const [onDeleteCancel, setOnDeleteCancel] = useState(() => () => setConfirmDeleteOpen(false));

    const confirmReadingDelete = (reading: Zaehlerstand) => {
        setConfirmDeleteOpen(true);
        setDeleteMessage('Wollen Sie wirklich den Zählerstand von ' + fmt.datum(reading.id.datum) + ' Löschen?');
        setOnDeleteCancel(() =>
            () => setConfirmDeleteOpen(false)
        );
        setOnDeleteOk(() =>
            () => { deleteReading(reading); setConfirmDeleteOpen(false); }
        );
        setCurrentReading(Zaehlerstand.DEFAULT());
    };

    const confirmNetzentgeltDelete = (netzentgelte: Netzentgelte) => {
        setConfirmDeleteOpen(true);
        setDeleteMessage('Wollen Sie wirklich den Netzentgelte von ' + fmt.datum(netzentgelte.id.preisstand) + ' Löschen?');
        setOnDeleteCancel(() => () => setConfirmDeleteOpen(false));
        setOnDeleteOk(() => () => { deleteNetzentgelte(netzentgelte); setConfirmDeleteOpen(false); });
        setCurrentNetzentgelte(Netzentgelte.DEFAULT());
    };

    const confirmVertragKostDelete = (vertragkost: VertragsKost) => {
        setConfirmDeleteOpen(true);
        setDeleteMessage('Wollen Sie wirklich die Vertrag-Info von ' + fmt.datum(vertragkost.preisstand) + ' Löschen?');
        setOnDeleteCancel(() => () => setConfirmDeleteOpen(false));
        setOnDeleteOk(() => () => { deleteVertragKost(vertragkost); setConfirmDeleteOpen(false); });
        setCurrentVertragKost(VertragsKost.DEFAULT());
    };

    const saveAngebot = async () => {
        try {

            if (zaehlerstanderfassung.id) {
                await ZaehlerstanderfassungService.updateZaehlerstanderfassung(zaehlerstanderfassung);
            } else {
                await ZaehlerstanderfassungService.createZaehlerstanderfassung(zaehlerstanderfassung, angebot?.id);
            }

            setSavingModalOpen(true);
            setMessages(prev => [...prev, 'Das Angebot wurde erfolgreich gespeichert.']);
            setNotSaved(false);
        } catch (error) {
            errorMessage(error);
            setErrors(prev => [...prev, 'Das Angebot wurde nicht erfolgreich gespeichert.']);
        } finally {
        }
    }

    useEffect(() => {
        let newZaehlerstanderfassung = Object.assign({}, zaehlerstanderfassung);

        zaehlerstanderfassung.netzentgeltList.forEach((n, i) => {
            let newN: Netzentgelte = { ...n };
            const fixKosten = calcFixKosten(n);
            const variableKosten = calcVariableKosten(n, zaehlerstanderfassung);
            newN.fixkosten = fixKosten;
            newN.variablekosten = variableKosten;
            newN.gesamtkosten = fmt.round(fixKosten, 2) + fmt.round(variableKosten / 100, 2);
            newZaehlerstanderfassung.netzentgeltList.splice(i, 1, newN);

        });

        zaehlerstanderfassung.vertragsKostList.forEach((v, i) => {
            let newV: VertragsKost = { ...v };
            const kosten = calcGesamtKosten(newV, zaehlerstanderfassung);
            const energiekosten = calcEnergieKosten(newV, newZaehlerstanderfassung);
            const energiepreis = calcEnergiePreis(newV, newZaehlerstanderfassung);
            newV.gesamtkosten = kosten;
            newV.energiekosten = energiekosten;
            newV.energiepreis = energiepreis;
            newZaehlerstanderfassung.vertragsKostList.splice(i, 1, newV);
        });

        setZaehlerstanderfassung(newZaehlerstanderfassung);
    }, [zaehlerstanderfassung.lieferstelle.verbrauch]);

    const [savingModalOpen, setSavingModalOpen] = useState(false);

    const isDirty = () => {
        const dirty = notSaved;
        return dirty;
    };

    usePrompt({ message: 'Deine Änderungen werden eventuell nicht gespeichert.', when: isDirty() });

    useEffect(() => {
        if (!isDirty() && errors.length === 0) {
            window.removeEventListener('beforeunload', handleBeforeunloadEvent);
            setTimeout(() => navigate('/offers'), 500);
        }
    }, [notSaved, errors]);

    return (
        <Layout>
            <Topbar />
            <Layout>
                <Sidebar />
                <Content style={{
                    padding: 20,
                    boxShadow: '0px 0px 15px -10px rgba(0,0,0,0.75)',
                    margin: 20,
                }}>
                    <Divider style={{ marginTop: 'auto' }}></Divider>
                    <Modal destroyOnClose={true} open={confirmDeleteOpen}
                        maskClosable={false} okButtonProps={{ danger: true }} title="Löschen bestätigen?" onOk={onDeleteOk} okText={'Bestätigen'} onCancel={onDeleteCancel}>
                        <p>{deleteMessage}</p>
                    </Modal>
                    <Modal destroyOnClose={true} maskClosable={false} open={savingModalOpen} closable={false} footer={errors.length ? [<Button key="back" onClick={() => {
                        setErrors([]);
                        setMessages([]);
                        setSavingModalOpen(false);
                    }}>
                        schließen
                    </Button>,
                    <Button key="submit" type="primary" onClick={() => { setErrors([]); setSavingModalOpen(false); saveAngebot(); }}>
                        nochmal versuchen
                    </Button>] : null} title={'Die Daten werden gespeichert...'}>
                        <Row justify='center'>
                            <Spin spinning size='large'></Spin>
                        </Row>
                        <Row>
                            {
                                messages.map(message => <p><CheckCircleTwoTone twoToneColor="#52c41a" />{message}</p>)
                            }
                        </Row>
                        <Row>
                            {
                                errors.map(error => <p><CloseCircleTwoTone twoToneColor="#eb2f96" />{error}</p>)
                            }
                        </Row>
                    </Modal>
                    <Row gutter={[8, 8]} justify="start">
                        <Col span={6}>
                            <Affix offsetTop={100}>
                                <Steps current={current} onChange={curr => {
                                    setCurrent(curr);
                                }} direction={'vertical'}>
                                    <Step key={'Energieart'} style={{ height: 120 }} title={'Energieart'} onClick={() => setView('0')}></Step>
                                    <Step key={'Rechnungsadresse'} style={{ height: 120 }} title={'Rechnungsadresse'} onClick={() => setView('1')}></Step>
                                    <Step key={'Kunde'} style={{ height: 120 }} title={'Kunde'} onClick={() => setView('2')}></Step>
                                    <Step key={'Lieferstelle'} style={{ height: 120 }} title={'Lieferstelle'} onClick={() => setView('3')}></Step>
                                    <Step key={'Netzentgelte'} style={{ height: 120 }} title={'Netzentgelte'} onClick={() => setView('4')}></Step>
                                    <Step key={'Versorgungssituation'} style={{ height: 120 }} title={'Versorgungssituation'} onClick={() => setView('5')}></Step>
                                    <Step key={'Kostenvergleich'} style={{ height: 120 }} title={'Kostenvergleich'} onClick={() => setView('6')}></Step>
                                    <Step key={'speichern'} style={{ height: 120 }} title={'Speichen & Beenden'} onClick={() => {
                                        setView('7');
                                        saveAngebot();
                                    }}></Step>
                                </Steps>
                            </Affix>
                        </Col>
                        <Col span={18} style={{ minHeight: 800 }}>
                            <Form layout="vertical">
                                <Row id="0" gutter={[8, 8]}>
                                    <Divider orientation="left">Energieart</Divider>
                                    <Col span={12}>
                                        <Form.Item required label={'Energieart'}>
                                            <Select
                                                value={zaehlerstanderfassung.lieferstelle.energieartId}
                                                onSelect={value => {
                                                    setZaehlerstanderfassung(prev => {
                                                        let newValue = { ...prev };
                                                        newValue.lieferstelle.energieartId = value;
                                                        if (value === Energieart.GAS) {
                                                            newValue.lieferstelle.faktor = 0;
                                                        } else {
                                                            newValue.lieferstelle.faktor = 1;
                                                        }
                                                        return newValue;
                                                    });
                                                    setCurrent(1);
                                                }}>
                                                {lookup.energieartList.map(ea => <Option label={ea.energieart} key={ea.id} value={ea.id}>{ea.energieart}</Option>)}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row hidden={current < 1} id="1">
                                    <Divider orientation="left">Kunde</Divider>
                                    <Col span={12}>
                                        <Form.Item label="Name / Firmenname" required>
                                            {
                                                isNew ?
                                                    <SearchInput<Kunde>
                                                        value={zaehlerstanderfassung.kunde}
                                                        onValueChange={
                                                            (selectedKunde: Kunde) => {
                                                                if (selectedKunde.id !== zaehlerstanderfassung.kunde?.id) {
                                                                    setZaehlerstanderfassung(prev => { return { ...prev, kunde: selectedKunde } });
                                                                    setCurrentVertragKost(VertragsKost.DEFAULT());
                                                                    setAddressList([selectedKunde.adressen?.find(a => a.adressart === AddressType.RECHNUNG)!]);
                                                                }
                                                                setCurrent(2);
                                                            }
                                                        }
                                                        valueProperty={'id'}
                                                        labelFormatter={fmt.kunde}
                                                        popupMatchSelectWidth={1500}
                                                        menuHeader={
                                                            <Row align="middle" justify="center">
                                                                <Col span={9}><p>Kunde</p></Col>
                                                                <Col span={4}><p>Rechtsform</p></Col>
                                                                <Col span={9}><p>Adresse</p></Col>
                                                                <Col span={2}><p>Kunde von</p></Col>
                                                            </Row>
                                                        }
                                                        fetchDataRequest={
                                                            (keyword: string, signal: AbortSignal) => {
                                                                const result = KundenService.getAllKundenByName(keyword, signal);
                                                                return result;
                                                            }
                                                        }
                                                        filter={(k: Kunde) => k.smartDifferent}
                                                        render={(customer: Kunde) =>
                                                                <Row>
                                                                    <Col span={9}>{fmt.kunde(customer)}</Col>
                                                                    <Col span={4}>{lookup.rechtsformList.find((r) => r.id === customer.rechtsform)?.rechtsform}</Col>
                                                                    <Col span={9}>{fmt.adresse(customer.adressen?.at(0))}</Col>
                                                                    <Col span={2}>{fmt.mandant(customer)}</Col>
                                                                </Row>}
                                                    >
                                                    </SearchInput>
                                                    :
                                                    [fmt.kunde(zaehlerstanderfassung.kunde), fmt.adresse(zaehlerstanderfassung.kunde?.adressen?.at(0))].filter(hasText).join(', ')
                                            }

                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row hidden={current < 2} id="2">
                                    <Divider orientation="left">Rechnungsadresse</Divider>
                                    <Col span={12}>
                                        <Form.Item label="Rechnungsadresse" required>
                                            <Select
                                                value={zaehlerstanderfassung.rechnungsadresse?.id}
                                                onSelect={(value: number) => {
                                                    const selectedAddress = zaehlerstanderfassung.kunde?.adressen?.find(a => a.id === value);
                                                    setZaehlerstanderfassung(prev => {
                                                        return { ...prev, rechnungsadresse: selectedAddress }
                                                    });
                                                    setCurrent(3);
                                                }}
                                                options={
                                                    zaehlerstanderfassung.kunde?.adressen?.map(address => {
                                                        return {
                                                            label: fmt.adresse(address),
                                                            value: address.id,
                                                            key: address.id,
                                                        };
                                                    })
                                                }
                                                dropdownRender={menu => zaehlerstanderfassung.kunde?.adressen?.find(a => a.adressart === AddressType.RECHNUNG) ? <Layout>{menu}</Layout> : <Empty />}
                                            >
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row hidden={current < 3} id="3" gutter={[8, 8]}>
                                    <Divider orientation="left">Lieferstelle</Divider>
                                    <Col span={12}>
                                        <Form.Item label="Die Lieferstelle Übernehmen">
                                            <Select onChange={value => takeOverAddress(value)}
                                                optionLabelProp="label"
                                                options={
                                                    addressList.map((address, i) => {
                                                        return {
                                                            label: fmt.adresse(address),
                                                            value: fmt.adresse(address),
                                                            key: i,
                                                        };
                                                    })
                                                }
                                                dropdownRender={menu => hasElems(addressList) ? <Layout>{menu}</Layout> : <Empty />}
                                            >
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}></Col>
                                    <Col span={6}>
                                        <Form.Item label="Straße">
                                            <Input value={zaehlerstanderfassung.lieferstelle.strasse} onChange={e => handleLieferstelleChange('strasse', e.target.value)}></Input>
                                        </Form.Item>
                                    </Col>
                                    <Col span={2}>
                                        <Form.Item label="Hausnummer">
                                            <Input value={zaehlerstanderfassung.lieferstelle.hausnr} onChange={e => handleLieferstelleChange('hausnr', e.target.value)}></Input>
                                        </Form.Item>
                                    </Col>
                                    <Col span={2}>
                                        <Form.Item label="Zusatz">
                                            <Input value={zaehlerstanderfassung.lieferstelle.zusatz} onChange={e => handleLieferstelleChange('zusatz', e.target.value)}></Input>
                                        </Form.Item>
                                    </Col>
                                    <Col span={2}>
                                        <Form.Item label="PLZ" validateStatus={plzStatus} help={plzHelp}>
                                            <Input maxLength={5} value={zaehlerstanderfassung.lieferstelle.plz}
                                                onChange={e => {
                                                    handlePlzChange(e.target.value);
                                                }}></Input>
                                        </Form.Item>
                                    </Col>
                                    <Col span={4}>
                                        <Form.Item label="Ort">
                                            <Input value={zaehlerstanderfassung.lieferstelle.ort} onChange={e => handleLieferstelleChange('ort', e.target.value)}></Input>
                                        </Form.Item>
                                    </Col>
                                    <Col span={4}>
                                        <Form.Item label="Ortsteil">
                                            <Input value={zaehlerstanderfassung.lieferstelle.ortsteil} onChange={e => handleLieferstelleChange('ortsteil', e.target.value)}></Input>
                                        </Form.Item>
                                    </Col>
                                    <Col span={4}>
                                        <Form.Item label="Land">
                                            <Input value={zaehlerstanderfassung.lieferstelle.land} onChange={e => handleLieferstelleChange('land', e.target.value)}></Input>
                                        </Form.Item>
                                    </Col>
                                    <Col span={10}>
                                        <Form.Item label="Messlokation" validateStatus={meloStatus} help={meloMessage} hasFeedback>
                                            <Input maxLength={36}
                                                value={fmt.melo.print(zaehlerstanderfassung.lieferstelle.messlokation)}
                                                onChange={e => {
                                                    const value = fmt.melo.parse(e.target.value);
                                                    handleLieferstelleChange('messlokation', value);
                                                }}></Input>
                                        </Form.Item>
                                    </Col>
                                    <Col span={10}>
                                        <Form.Item label="Marktlokation" hasFeedback help={maloMessage} validateStatus={maloStatus}>
                                            <Input maxLength={11} value={zaehlerstanderfassung.lieferstelle.marktlokation} onChange={e => handleLieferstelleChange('marktlokation', e.target.value)}></Input>
                                        </Form.Item>
                                    </Col>
                                    <Col span={4}>
                                        <Form.Item label="Individuelles Messekonzept">
                                            <Select value={zaehlerstanderfassung.lieferstelle.individuell} onChange={value => handleLieferstelleChange("individuell", value)}>
                                                <Option value={false}>Nicht vorhanden.</Option>
                                                <Option value={true}>Ja.</Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={3}>
                                        <Form.Item label="Profil">
                                            <Select value={zaehlerstanderfassung.lieferstelle.profil} aria-autocomplete="list"
                                                onChange={value => { if (value !== 5) { handleLieferstelleChange('spitze', null); } handleLieferstelleChange('profil', value); }}>
                                                {
                                                    lookup.zaehlerProfilList.filter(p => {
                                                        if (zaehlerstanderfassung.lieferstelle.energieartId === Energieart.GAS) {
                                                            return p.profil === 'SLP' || p.profil === 'RLM';
                                                        }
                                                        return true;
                                                    }).map(z => <Option key={z.id} value={z.id}>{z.profil}</Option>)
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={3}>
                                        <Form.Item label="Jahresverbrauch">
                                            <InputNumber decimalSeparator=","
                                                formatter={value => value ? fmt.deutschNumberFormat(value) : ''}
                                                parser={value => value ? fmt.deutschNumberParser(value) : 0}
                                                style={{ width: '100%' }} controls={false} value={zaehlerstanderfassung.lieferstelle.verbrauch} onChange={e => handleLieferstelleChange('verbrauch', e)}></InputNumber>
                                        </Form.Item>
                                    </Col>
                                    <Col span={3} hidden={zaehlerstanderfassung.lieferstelle.energieartId === Energieart.GAS}>
                                        <Form.Item label="Wandlerfaktor">
                                            <InputNumber decimalSeparator=","
                                                formatter={value => value ? fmt.deutschNumberFormat(value) : ''}
                                                parser={value => value ? fmt.deutschNumberParser(value) : 0}
                                                style={{ width: '100%' }} controls={false} value={zaehlerstanderfassung.lieferstelle.faktor} onChange={e => handleLieferstelleChange('faktor', e)}></InputNumber>
                                        </Form.Item>
                                    </Col>
                                    <Col span={3} hidden={zaehlerstanderfassung.lieferstelle.energieartId !== Energieart.GAS}>
                                        <Form.Item label="Zustandszahl">
                                            <InputNumber decimalSeparator=","
                                                formatter={value => value ? fmt.deutschNumberFormat(value) : ''}
                                                parser={value => value ? fmt.deutschNumberParser(value) : 0}
                                                style={{ width: '100%' }} controls={false} value={zaehlerstanderfassung.lieferstelle.zustandszahl} onChange={e => handleLieferstelleChange('zustandszahl', e)}></InputNumber>
                                        </Form.Item>
                                    </Col>
                                    <Col span={3} hidden={zaehlerstanderfassung.lieferstelle.energieartId !== Energieart.GAS}>
                                        <Form.Item label="Brennwert">
                                            <InputNumber decimalSeparator=","
                                                formatter={value => value ? fmt.deutschNumberFormat(value) : ''}
                                                parser={value => value ? fmt.deutschNumberParser(value) : 0}
                                                style={{ width: '100%' }} controls={false} value={zaehlerstanderfassung.lieferstelle.brennwert} onChange={e => handleLieferstelleChange('brennwert', e)}></InputNumber>
                                        </Form.Item>
                                    </Col>
                                    <Col span={3}>
                                        <Form.Item label="Leistungsspitze">
                                            <InputNumber decimalSeparator=","
                                                formatter={value => value ? fmt.deutschNumberFormat(value) : ''}
                                                parser={value => value ? fmt.deutschNumberParser(value) : 0}
                                                style={{ width: '100%' }} controls={false} disabled={zaehlerstanderfassung.lieferstelle.profil !== 5} value={zaehlerstanderfassung.lieferstelle.spitze} onChange={e => handleLieferstelleChange('spitze', e)}></InputNumber>
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Divider orientation="left">Zählerstand</Divider>
                                        Neuen Zählerstand erfassen: <Button ghost type="primary" onClick={openReadingsModal}><ProfileOutlined /></Button>
                                        <Modal width={1000}
                                            maskClosable={false}
                                            title="Zählerstände erfassen"
                                            open={readingsModalOpen}
                                            onCancel={closeReadingsModal}
                                            footer={[
                                                <Button key="submit" type="primary" disabled={!readingReady} onClick={() => saveReading()}>
                                                    Speichern
                                                </Button>,
                                            ]}>
                                            <Form layout="vertical">
                                                <Row>
                                                    <Col>
                                                        <Alert message={readingErrorMessage} type="error" showIcon banner style={{ visibility: hasText(readingErrorMessage) ? 'visible' : 'hidden' }}></Alert>
                                                    </Col>
                                                </Row>
                                                <Row gutter={[8, 8]}>
                                                    <Col span={4}>
                                                        <Form.Item label="Datum">
                                                            <Input type="date" value={currentReading.id.datum} onChange={e => {
                                                                let newValue = Object.assign({}, currentReading);
                                                                const newId = {
                                                                    ...newValue.id,
                                                                    datum: e.target.value,
                                                                };
                                                                handleReadingChange('id', newId);
                                                            }}></Input>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={zaehlerstanderfassung.lieferstelle.energieartId === Energieart.GAS ? 8 : 4}>
                                                        <Form.Item label={zaehlerstanderfassung.lieferstelle.energieartId === Energieart.GAS ? 'Gas' : 'ET'}>
                                                            <InputNumber style={{ width: '100%' }} decimalSeparator="," controls={false} value={currentReading.et} disabled={(currentReading.ht !== undefined && currentReading.ht !== 0 && !isNaN(currentReading.ht)) || (currentReading.nt !== undefined && currentReading.nt !== 0 && !isNaN(currentReading.nt))} onChange={value => handleReadingChange('et', value)}></InputNumber>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={4} hidden={zaehlerstanderfassung.lieferstelle.energieartId === Energieart.GAS}>
                                                        <Form.Item label="HT">
                                                            <InputNumber style={{ width: '100%' }} decimalSeparator="," controls={false} value={currentReading.ht} disabled={currentReading.et !== undefined && currentReading.et !== 0 && !isNaN(currentReading.et)} onChange={value => handleReadingChange('ht', value)}></InputNumber>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={4} hidden={zaehlerstanderfassung.lieferstelle.energieartId === Energieart.GAS}>
                                                        <Form.Item label="NT">
                                                            <InputNumber style={{ width: '100%' }} decimalSeparator="," controls={false} value={currentReading.nt} disabled={currentReading.et !== undefined && currentReading.et !== 0 && !isNaN(currentReading.et)} onChange={value => handleReadingChange('nt', value)}></InputNumber>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={8}>
                                                        <Form.Item label="Zählernummer" wrapperCol={{ span: 24 }} validateStatus={znStatus} help={znMessage}>
                                                            <Input value={currentReading.zaehlernummer} onChange={e => handleReadingChange('zaehlernummer', e.target.value)}></Input>
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </Modal>
                                    </Col>
                                    <Col span={24} hidden={zaehlerstanderfassung.zaehlerstandList.length === 0}>
                                        <Table
                                            bordered
                                            style={{ marginTop: 10 }}
                                            pagination={false}
                                            dataSource={[...zaehlerstanderfassung.zaehlerstandList]}
                                            columns={[
                                                {
                                                    title: 'Datum',
                                                    dataIndex: 'id',
                                                    sorter: { compare: (a: Zaehlerstand, b: Zaehlerstand) => dayjs(a.id.datum).valueOf() - dayjs(b.id.datum).valueOf(), multiple: 1 },
                                                    render: (value: Zaehlerstand['id']) => dayjs(value.datum).format('DD.MM.YYYY'),
                                                },
                                                {
                                                    title: 'ET',
                                                    dataIndex: 'et',
                                                    sorter: { compare: (a: Zaehlerstand, b: Zaehlerstand) => numberOrZero(a.et) - numberOrZero(b.et), multiple: 2 },
                                                    render: (value: Zaehlerstand['et']) => value ? fmt.deutschNumberFormat(fmt.round(value, 3)) : '',
                                                },
                                                {
                                                    title: 'HT',
                                                    dataIndex: 'ht',
                                                    sorter: { compare: (a: Zaehlerstand, b: Zaehlerstand) => numberOrZero(a.ht) - numberOrZero(b.ht), multiple: 3 },
                                                    render: (value: Zaehlerstand['ht']) => value ? fmt.deutschNumberFormat(fmt.round(value, 3)) : '',
                                                },
                                                {
                                                    title: 'NT',
                                                    dataIndex: 'nt',
                                                    sorter: { compare: (a: Zaehlerstand, b: Zaehlerstand) => numberOrZero(a.nt) - numberOrZero(b.nt), multiple: 4 },
                                                    render: (value: Zaehlerstand['nt']) => value ? fmt.deutschNumberFormat(fmt.round(value, 3)) : '',
                                                },
                                                {
                                                    title: 'Zählernummer',
                                                    dataIndex: 'zaehlernummer',
                                                    sorter: { compare: (a: Zaehlerstand, b: Zaehlerstand) => a.zaehlernummer && b.zaehlernummer ? a.zaehlernummer.localeCompare(b.zaehlernummer) : 0, multiple: 5 },
                                                },
                                                {
                                                    title: 'Aktion',
                                                    dataIndex: 'action',
                                                    render: (_value: unknown, record: Zaehlerstand) => {
                                                        return <Row justify={'center'} align={'middle'} gutter={[8, 8]} wrap={false}>
                                                            <Col><Button title="bearbeiten" type="primary" ghost icon={<EditOutlined />} onClick={() => editReading(record, zaehlerstanderfassung.zaehlerstandList.findIndex(z => z === record))}></Button></Col>
                                                            <Col><Button title="löschen" danger icon={<DeleteOutlined />} onClick={() => {
                                                                confirmReadingDelete(record);
                                                            }} /></Col>
                                                        </Row>
                                                    },
                                                }
                                            ].filter(c => {
                                                if (zaehlerstanderfassung.lieferstelle.energieartId === Energieart.GAS) {
                                                    return c.dataIndex !== 'ht' && c.dataIndex !== 'nt';
                                                }
                                                return true;
                                            })}
                                            footer={() =>
                                                <Row gutter={[8, 8]}>
                                                    <Col>Jahresprognose</Col>
                                                    <Col>{getGesamt()}</Col>
                                                </Row>} />
                                    </Col>
                                </Row>
                                <Row hidden={current < 4} id="4">
                                    <Divider orientation="left">Netzentgelte, Steuer, Umlagen</Divider>
                                    <Col span={24}>
                                        <Button style={{ marginBottom: 10 }} type="default" href="https://cockpit.get-ag.com/Netzentgelt/Netzentgeltrechner/STROM" target="_blank">
                                            {zaehlerstanderfassung.lieferstelle.energieartId === Energieart.GAS ? 'Gas-Netzentgeltrechner' : 'Strom-Netzentgeltrechner'}
                                        </Button>
                                    </Col>
                                    <Col span={24}>
                                        <EditNetzentgelte
                                            open={netzentgelteModalOpen}
                                            openModal={openNetzentgelteModal}
                                            closeModal={closeNetzentgelteModal}
                                            currentNetzentgelte={currentNetzentgelte}
                                            setCurrentNetzentgelte={setCurrentNetzentgelte}
                                            isRLM={zaehlerstanderfassung.lieferstelle.profil === 5}
                                            saveModal={() => saveNetzentgelte(currentNetzentgelte)}
                                            lastRecord={hasElems(nsu) ? nsu.reduce((p, c) => (p.id.preisstand > c.id.preisstand ? p : c)) : undefined}
                                            gas={zaehlerstanderfassung.lieferstelle.energieartId === Energieart.GAS}
                                        />
                                    </Col>
                                    <Col span={24} hidden={zaehlerstanderfassung.netzentgeltList.length === 0}>
                                        <Table
                                            bordered
                                            style={{ marginTop: 10 }}
                                            dataSource={[...zaehlerstanderfassung.netzentgeltList]}
                                            pagination={false}
                                            columns={[{
                                                title: 'Gesamtkosten',
                                                dataIndex: 'gesamtkosten',
                                                sorter: { compare: (a: Netzentgelte, b: Netzentgelte): number => 
                                                    + (calcFixKosten(a) + (calcVariableKosten(a, zaehlerstanderfassung) / 100))
                                                    - (calcFixKosten(b) + (calcVariableKosten(b, zaehlerstanderfassung) / 100)), 
                                                    multiple: 1 },
                                                render: (value: number) => {
                                                    if (value) {
                                                        return fmt.deutschNumberFormat(value) + ' €/Jahr';
                                                    }
                                                    return <Tag color="red">Benötigte Daten fehlen. Bitte überprüfen.</Tag>
                                                }
                                            },
                                            {
                                                title: 'Fixkosten',
                                                dataIndex: 'fixkosten',
                                                sorter: { compare: (a, b) => 
                                                    + calcFixKosten(a) 
                                                    - calcFixKosten(b), 
                                                    multiple: 2 },
                                                render: (value: number) => {
                                                    if (value) {
                                                        return fmt.deutschNumberFormat(fmt.round(value, 2)) + ' €/Jahr';
                                                    }
                                                    return <Tag color="red">Benötigte Daten fehlen. Bitte überprüfen.</Tag>
                                                }
                                            },
                                            {
                                                title: 'Variable Kosten',
                                                dataIndex: 'variablekosten',
                                                sorter: { compare: (a, b) => 
                                                    + calcVariableKosten(a, zaehlerstanderfassung) 
                                                    - calcVariableKosten(b, zaehlerstanderfassung), 
                                                    multiple: 3 },
                                                render: (value: number) => {
                                                    if (value) {
                                                        return fmt.deutschNumberFormat(fmt.round(value / 100, 2)) + ' €/Jahr';
                                                    }
                                                    return <Tag color="red">Benötigte Daten fehlen. Bitte überprüfen.</Tag>
                                                }
                                            },
                                            {
                                                title: 'Preisstand',
                                                dataIndex: 'id',
                                                sorter: { compare: (a, b) => dayjs(a.id.preisstand).valueOf() - dayjs(b.id.preisstand).valueOf(), multiple: 4 },
                                                render: (value) => fmt.datum(value.preisstand),
                                            },
                                            {
                                                title: 'Aktion',
                                                dataIndex: 'action',
                                                render: (_value: unknown, record: Netzentgelte) => {
                                                    return <Row justify={'center'} align={'middle'} gutter={[8, 8]} wrap={false}>
                                                        <Col><Button title="bearbeiten" type="primary" ghost icon={<EditOutlined />} onClick={() => editNetzentgelte(record, zaehlerstanderfassung.netzentgeltList.findIndex(n => n === record))}></Button></Col>
                                                        <Col><Button title="löschen" danger icon={<DeleteOutlined />} onClick={() => {
                                                            confirmNetzentgeltDelete(record);
                                                        }} /></Col>
                                                    </Row>
                                                }
                                            }
                                            ]}></Table>
                                    </Col>
                                </Row>
                                <Row hidden={current < 5} id="5" gutter={[8, 8]}>
                                    <Divider orientation="left">Versorgungssituation</Divider>
                                    <Col span={6}>
                                        <Form.Item label="Typ">
                                            <Select value={zaehlerstanderfassung.lieferstelle.versorgungssituation} onChange={value => handleLieferstelleChange('versorgungssituation', value)}>
                                                {
                                                    Object.values(Versorgungssituation).map(v =>
                                                        <Option key={v} value={v}>{v}</Option>)
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item label="Lieferbeginn" hidden={zaehlerstanderfassung.lieferstelle.versorgungssituation !== Versorgungssituation.NEUEINZUG}>
                                            <Input type='date' value={zaehlerstanderfassung.lieferstelle.neueinzuglieferbeginn} onChange={e => handleLieferstelleChange('neueinzuglieferbeginn', e.target.value)}></Input>
                                        </Form.Item>
                                    </Col>
                                    <Col span={24} hidden={zaehlerstanderfassung.lieferstelle.versorgungssituation !== Versorgungssituation.VERTRAGSWECHSEL}>
                                        <Divider orientation="left">Aktueller Versorger</Divider>
                                    </Col>
                                    <Col span={8} hidden={zaehlerstanderfassung.lieferstelle.versorgungssituation !== Versorgungssituation.VERTRAGSWECHSEL}>
                                        <Form.Item label="Kundennummer / Vertragsnummer">
                                            <Input value={zaehlerstanderfassung.lieferstelle.kundenvertragsnummer} onChange={e => handleLieferstelleChange('kundenvertragsnummer', e.target.value)}></Input>
                                        </Form.Item>
                                    </Col>
                                    <Col span={8} hidden={zaehlerstanderfassung.lieferstelle.versorgungssituation !== Versorgungssituation.VERTRAGSWECHSEL}>
                                        <Form.Item label="Versorger">
                                            <SearchInput<Kunde>
                                                popupMatchSelectWidth={1000}
                                                value={zaehlerstanderfassung.lieferstelle.versorger}
                                                valueProperty={'id'}
                                                label={zaehlerstanderfassung.lieferstelle.versorger?.name}
                                                fetchDataRequest={KundenService.getAllVersorger}
                                                onValueChange={(value) => handleLieferstelleChange('versorger', value)}
                                                menuHeader={<Row align="middle" justify="center">
                                                    <Col span={12}><p>Kunde</p></Col>
                                                    <Col span={10}><p>Adresse</p></Col>
                                                    <Col span={2}><p>Kunde von</p></Col>
                                                </Row>}
                                                labelFormatter={customer => [fmt.kunde(customer), fmt.adresse(customer.adressen?.at(0))].filter(hasText).join(', ')}
                                                render={(customer) => <Row>
                                                    <Col span={12}>{fmt.kunde(customer)}</Col>
                                                    <Col span={10}>{fmt.adresse(customer.adressen?.at(0))}</Col>
                                                    <Col span={2}>{fmt.mandant(customer)}</Col>
                                                </Row>}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8} hidden={zaehlerstanderfassung.lieferstelle.versorgungssituation !== Versorgungssituation.VERTRAGSWECHSEL}>
                                        <Form.Item label={' '}>
                                            <Button ghost type="primary" href={`/#/customer?branche=${BRANCHE_ID_EVU}`} target="_blank"><PlusOutlined></PlusOutlined></Button>
                                        </Form.Item>
                                    </Col>
                                    <Col span={24} hidden={zaehlerstanderfassung.lieferstelle.versorgungssituation !== Versorgungssituation.VERTRAGSWECHSEL}>
                                        <VertragspartnerWechselLieferbeginn
                                            type={zaehlerstanderfassung.lieferstelle.vertragspartnerwechsellieferbeginntyp}
                                            onTypeChange={(type) => handleLieferstelleChange('vertragspartnerwechsellieferbeginntyp', type)}
                                            date={zaehlerstanderfassung.lieferstelle.vertragspartnerwechsellieferbeginn}
                                            onDateChange={(date) => handleLieferstelleChange('vertragspartnerwechsellieferbeginn', date)}>
                                        </VertragspartnerWechselLieferbeginn>
                                    </Col>
                                    <Col span={24} hidden={zaehlerstanderfassung.lieferstelle.versorgungssituation !== Versorgungssituation.VERTRAGSWECHSEL}>
                                        <Kuendigungsfrist
                                            type={zaehlerstanderfassung.lieferstelle.kuendungsfristtyp}
                                            onTypeChange={type => {
                                                handleLieferstelleChange('kuendungsfristtyp', type);
                                                if (type !== KuendungsfristTyp.FRIST) {
                                                    handleLieferstelleChange('fristanzahl', undefined);
                                                    handleLieferstelleChange('fristeinheit', undefined);
                                                }
                                            }}
                                            fristNumber={zaehlerstanderfassung.lieferstelle.fristanzahl}
                                            onFristNumberChange={value => handleLieferstelleChange('fristanzahl', value)}
                                            fristEinheit={zaehlerstanderfassung.lieferstelle.fristeinheit}
                                            onFristEinheitChange={einheit => handleLieferstelleChange('fristeinheit', einheit)}>
                                        </Kuendigungsfrist>
                                    </Col>
                                </Row>
                                <Row hidden={current < 6} id="6">
                                    <Divider orientation="left">Kostenvergleich</Divider>
                                    <Col span={24}>
                                        <EditVertragInfo
                                            open={vertragInfoModalOpen}
                                            openModal={openVertragInfoModal}
                                            saveModal={saveVertragKost}
                                            closeModal={closeVertragInfoModal}
                                            currentVertragKost={currentVertragKost}
                                            setCurrentVertragKost={setCurrentVertragKost}
                                            gas={zaehlerstanderfassung.lieferstelle.energieartId === Energieart.GAS} />
                                    </Col>
                                    <Col span={24} style={{ marginTop: 10 }} hidden={zaehlerstanderfassung.vertragsKostList.length === 0}>
                                        <Table
                                            bordered
                                            pagination={false}
                                            dataSource={[...zaehlerstanderfassung.vertragsKostList]}
                                            columns={
                                                [{
                                                    title: 'Versorger',
                                                    dataIndex: 'versorger',
                                                    sorter: { compare: (a, b) => fmt.kunde(a.versorger).localeCompare(fmt.kunde(b.versorger)), multiple: 1 },
                                                    render: (value: Kunde) => {
                                                        return fmt.kunde(value);
                                                    },
                                                },
                                                {
                                                    title: 'Gesamtkosten',
                                                    dataIndex: 'gesamtkosten',
                                                    sorter: { compare: (a, b) => 
                                                        + calcGesamtKosten(a, zaehlerstanderfassung) 
                                                        - calcGesamtKosten(b, zaehlerstanderfassung), 
                                                        multiple: 2 },
                                                    render: (value: number) => {
                                                        return value ? fmt.deutschNumberFormat(fmt.round(value, 2)) + ' €/Jahr' : <Tag color="red">Benötigte Daten fehlen. Bitte überprüfen.</Tag>;
                                                    },
                                                },
                                                {
                                                    title: 'Energiekosten',
                                                    dataIndex: 'energiekosten',
                                                    sorter: { compare: (a, b) => 
                                                        + calcEnergieKosten(a, zaehlerstanderfassung) 
                                                        - calcEnergieKosten(b, zaehlerstanderfassung), 
                                                        multiple: 3 },
                                                    render: (value: number) => {
                                                        return value ? fmt.deutschNumberFormat(fmt.round(value, 2)) + ' €/Jahr' : <Tag color="red">Benötigte Daten fehlen. Bitte überprüfen.</Tag>;

                                                    },
                                                },
                                                {
                                                    title: 'Energiepreis',
                                                    dataIndex: 'energiepreis',
                                                    sorter: { compare: (a, b) => 
                                                        + calcEnergiePreis(a, zaehlerstanderfassung) 
                                                        - calcEnergiePreis(b, zaehlerstanderfassung), 
                                                        multiple: 4 },
                                                    render: (value: number) => {
                                                        return value ? fmt.deutschNumberFormat(fmt.round(value, 3)) + ' Cent/kW' : <Tag color="red">Benötigte Daten fehlen. Bitte überprüfen.</Tag>;
                                                    },
                                                },
                                                {
                                                    title: 'Tarifname',
                                                    dataIndex: 'tarifname',
                                                    sorter: { compare: (a, b) => a.tarifname && b.tarifname ? a.tarifname.localeCompare(b.tarifname) : 0, multiple: 5 },
                                                },
                                                {
                                                    title: 'Preisstand',
                                                    dataIndex: 'preisstand',
                                                    sorter: { compare: (a, b) => dayjs(a.preisstand).isBefore(b.preisstand) ? -1 : 1, multiple: 6 },
                                                    render: (value) => fmt.datum(value),
                                                },
                                                {
                                                    title: 'Kostenvergleich Relevant',
                                                    render: (_, record: VertragsKost) => <Radio
                                                        checked={record.relevant} onClick={() => handleRelevantVertragKostChange(record)}></Radio>
                                                },
                                                {
                                                    title: 'Aktion',
                                                    dataIndex: 'action',
                                                    render: (_value: unknown, record: VertragsKost) => {
                                                        return <Row justify={'center'} align={'middle'} gutter={[8, 8]} wrap={false}>
                                                            <Col><Button title="bearbeiten" type="primary" ghost icon={<EditOutlined />} onClick={() => editVertragKost(record, zaehlerstanderfassung.vertragsKostList.findIndex(v => v === record))} /></Col>
                                                            <Col><Button title="löschen" danger icon={<DeleteOutlined />} onClick={() => {
                                                                confirmVertragKostDelete(record);
                                                            }} /></Col>
                                                        </Row>
                                                    }
                                                }
                                                ]}
                                        />
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                    <Row style={{ position: 'sticky', bottom: 0, paddingTop: 10, paddingBottom: 10, backgroundColor: 'rgba(255,255,255,1)' }}>
                        <Col span={3} offset={6}>
                            {
                                current > 0 && (
                                    <Button size='middle' onClick={() => prev()}>
                                        zurück
                                    </Button>
                                )
                            }
                        </Col>
                        <Col span={15}>
                            {
                                current < 7 && (
                                    <Button size='middle' style={{ float: 'right', marginRight: 5 }} onClick={() => next()}>
                                        weiter
                                    </Button>
                                )
                            }
                            {
                                current === 7 && (
                                    <Button type="primary" size='middle' style={{ float: 'right', marginRight: 5 }} onClick={() => saveAngebot()}>
                                        speichern
                                    </Button>
                                )
                            }
                        </Col>
                    </Row>
                </Content>
            </Layout>
        </Layout >
    )
}


function validateMelo(melo: string, plz_lieferstelle: string): [ValidateStatus, string] {
    const meloLen = 33;
    const plzLen = 5;
    const plzStart = 8
    const plz = melo.slice(plzStart, plzStart + plz_lieferstelle.length);

    const alphanumCharsLabel = (count: number) =>
        count === 1
            ? 'Ziffer oder Buchstabe'
            : 'Ziffern oder Buchstaben';

    if (!/^[A-Za-z]{2}/.test(melo)) {
        return ["error", "Muss mit zwei Buchstaben beginnen."];
    } else if (melo.length < plzStart) {
        const missing = plzStart - melo.length;
        return ["error", `Bis zur PLZ fehlen noch ${missing} ${alphanumCharsLabel(missing)}.`];
    } else if (melo.length < meloLen) {
        if (plz !== plz_lieferstelle) {
            return ["error", "PLZ abweichend. Bitte überprüfen."];
        } else if (melo.length >= plzStart + plzLen) {
            const missing = meloLen - melo.length
            return ["error", `${missing} ${alphanumCharsLabel(missing)} zu wenig.`];
        }
    }

    if (plz === plz_lieferstelle) {
        return ["success", ""];
    } else {
        return ["warning", "PLZ abweichend. Bitte überprüfen."];
    }
}

type SingleDigit = '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9';

function calcPruefzifferMalo(malo: string): SingleDigit {
    let oddsum = 0;
    let evensum = 0;

    for (let i = 0; i < 10; i++) {
        if (i % 2 === 0) {
            oddsum += parseInt(malo.charAt(i));
        } else {
            evensum += parseInt(malo.charAt(i));
        }
    }

    const sum = oddsum + evensum * 2;
    const next10Multiple = Math.ceil(sum / 10) * 10;
    const checkDigit = next10Multiple - sum;
    return `${checkDigit % 10}` as SingleDigit;
}

function validateMalo(malo: string): [ValidateStatus, string] {
    const malo_len = 11;

    if (!/^[0-9]*$/.test(malo)) {
        return ['error', 'Nur Ziffern sind erlaubt.'];
    }
    if (malo.length < malo_len) {
        const diff = malo_len - malo.length;
        return ['error', `${diff} ${diff === 1 ? 'Ziffer' : 'Ziffern'} zu wenig.`];
    }
    if (malo.length > malo_len) {
        const diff = malo.length - malo_len;
        return ['error', `${diff} ${diff === 1 ? 'Ziffer' : 'Ziffern'} zu viel.`];
    }
    if (calcPruefzifferMalo(malo) !== malo.charAt(10)) {
        return ['error', 'Prüfziffer falsch.'];
    }
    return ['success', ''];
}


const validateZN = (
    currentReading: Zaehlerstand,
    zaehlerstandList: Zaehlerstand[],
    setZnStatus: (status: ValidateStatus) => void,
    setZnMessage: (message: string) => void,
    setReadingErrorMessage: (message: string) => void): boolean => {
    const meterNumberList = zaehlerstandList
        .map(d => d.zaehlernummer)
        .filter((value, index, array) => array.indexOf(value) === index)
        .filter(d => hasText(d))
    const meterAmount = meterNumberList.length;

    if (meterAmount === 1) {
        if (hasText(currentReading.zaehlernummer) && zaehlerstandList[0].zaehlernummer !== currentReading.zaehlernummer) {
            setZnStatus("warning");
            setZnMessage("Erfolgte ein Zählerwechsel?");
        } else {
            setZnStatus("");
            setZnMessage("");
        }
    }

    if (meterAmount === 2) {
        if (meterNumberList.every(d => d !== currentReading.zaehlernummer)) {
            setZnStatus("error");
            setZnMessage("Maximal 1 Zählerwechsel zulässig");
            return false;
        } else {
            setZnStatus("");
            setZnMessage("");
        }
    }

    if (meterAmount > 2) {
        setZnStatus("error");
        setZnMessage("Maximal 1 Zählerwechsel zulässig");
        return false;
    }

    const potentialErrorMeterReading = zaehlerstandList
        .filter(d => d.zaehlernummer === currentReading.zaehlernummer
            && ((dayjs(d.id.datum).diff(dayjs(currentReading.id.datum)) < 0 && (d.et! > currentReading.et! || d.ht! > currentReading.ht! || d.nt! > currentReading.nt!))
                || (dayjs(d.id.datum).diff(dayjs(currentReading.id.datum)) > 0 && (d.et! < currentReading.et! || d.ht! < currentReading.ht! || d.nt! < currentReading.nt!)))
        );

    if (potentialErrorMeterReading.length) {
        const first = potentialErrorMeterReading.sort((a, b) => dayjs(a.id.datum).diff(dayjs(b.id.datum))).at(0);
        setReadingErrorMessage(`Zählerstand kann nicht niedriger/höher als Stand vom ${fmt.datum(first?.id.datum)} sein`);
        return false;
    } else {
        setReadingErrorMessage("");
    }

    return currentReading.id.datum !== undefined && hasText(currentReading.zaehlernummer);
}

export default MeterReading;

export const __tests__ = {
    calcPruefzifferMalo,
    validateMalo,
    validateMelo,
    validateZN,
};
