import { SortOrder } from "antd/lib/table/interface";

export interface Kunde {
    id?: number;
    vorname?: string;
    name: string;
    zusatzbezeichnung?: string;
    geburtsname?: string;
    geburtsdatum?: string;
    geburtsort?: string;
    geschlecht?: number;
    nurEmailVersand?: boolean;
    homepage?: string;
    anrede?: string;
    kennzeichnungen?: number[];
    agenturen?: number[];
    funktion?: number[];
    ustid?: string;
    steuernr?: string;
    rechtsform?: number;
    branche?: number[];
    handelsregister?: string;
    hrnr?: string;
    vermittler?: Array<Vermittler>;
    bankverbindungen?: Bankverbindung[];
    bindungen?: Bindung[];
    kontakte?: Kontakt[];
    adressen?: KundenAdresse[];
    smartDifferent: boolean;
    smartInnovation: boolean;
    deleteDate?: string;
    bank?: Bank;
    staat?: number;
    color?: string;
    score?: number;
}

export type KundenStringKeys = 'vorname' |
    'name' |
    'zusatzbezeichnung' |
    'geburtsname' |
    'geburtsdatum' |
    'geburtsort' |
    'homepage' |
    'anrede' |
    'ustid' |
    'steuernr' |
    'handelsregister' |
    'hrnr';

export namespace Kunde {
    export const DEFAULT: () => Kunde = () => ({
        anrede: 'Sehr geehrte Damen und Herren',
        name: '',
        selektion: [],
        smartDifferent: false,
        smartInnovation: false,
    })
}

export interface Bankverbindung {
    id?: number;
    kunde?: number;
    kommentar?: string;
    bank?: number;
    konto?: string;
    bic?: string;
    iban?: string;
    blz?: string;
    erstellungszeitpunkt?: string;
    anpassungszeitpunkt?: string;
}

export namespace Bankverbindung {
    export const DEFAULT = (): Bankverbindung => ({
        blz: '',
        konto: '',
        iban: '',
        bic: '',
        kommentar: '',
    });
}

export interface KundenAdresse {
    id?: number;
    kunde?: number;
    strasse?: string;
    hausnr?: string;
    plz?: string;
    ort?: string;
    ortsteil?: string;
    land?: string;
    adressart?: number;
}

export namespace KundenAdresse {
    export const DEFAULT: () => KundenAdresse = () => ({
        strasse: '',
        hausnr: '',
        plz: '',
        ort: '',
        ortsteil: '',
        land: 'Deutschland',
    })
}

export interface AdditionalBindungsarten {
    id: number;
    rechtsform: number;
    bindungsart: number;
}

export interface KundePersonBindung {
    kunde?: number;
    person?: number;
    bindungsart?: number;
}

export type SyncResult = "OK" | "PARTIAL" | "FAILED" | "UNSYNCED" | "UNKNOWN"

export interface LeascomSyncState {
    id?: number,
    timestamp?: Date,
    kundeId: number,
    message?: string,
    syncClient?: string,
    syncResult?: SyncResult
}

export namespace lookup {

    export interface Agentur {
        id: number;
        agentur: string;
    }

    export interface Bindungsart {
        id: number;
        bindungsart: string;
        exklusiv: boolean;
        rueckbindungsart?: number;
    }

    export interface Branche {
        id: number;
        branche: string;
    }

    export interface Funktion {
        id: number,
        funktion: string,
    }

    export interface Kennzeichnung {
        id: number,
        kennzeichnung: string;
        color: string;
    }

    export interface Rechtsform {
        id: number;
        rechtsform: string;
        additionalBindungsarten: AdditionalBindungsarten[];

    }

    export interface Selektion {
        id: number;
        selektion: string;
    }

    export interface Kontaktart {
        id: number;
        kontaktart: string;
    }

    export interface Geschlecht {
        id: number;
        geschlecht: string;
    }

    export interface Vermittler {
        id: number;
        name: string;
        smartDifferent: boolean;
        smartInnovation: boolean;
    }

    export interface Customer {
        id: number;
        name: string;
    }

    export interface KontaktBezeichnung {
        id: number;
        kontakt_bezeichnung: string;
    }

    export interface Adressart {
        id: number;
        adressart: string;
    }

    export interface Quelle {
        id: number;
        quelle: string;
    }

    export interface Staat {
        id: number;
        staat: string;
    }

    export interface ZaehlerProfil {
        id: number;
        profil: string;
    }

    export interface Energeiart {
        id: number;
        energieart: string;
    }

}

export interface LookupLists {
    agenturList: Array<lookup.Agentur>,
    bindungsartList: Array<lookup.Bindungsart>,
    brancheList: Array<lookup.Branche>,
    funktionList: Array<lookup.Funktion>,
    kennzeichnungList: Array<lookup.Kennzeichnung>,
    rechtsformList: Array<lookup.Rechtsform>,
    selektionList: Array<lookup.Selektion>,
    kontaktartList: Array<lookup.Kontaktart>,
    geschlechtList: Array<lookup.Geschlecht>,
    customerList: Array<lookup.Customer>,
    kontaktBezeichnungList: Array<lookup.KontaktBezeichnung>,
    adressartList: Array<lookup.Adressart>,
    quelleList: Array<lookup.Quelle>,
    staatList: Array<lookup.Staat>,
    zaehlerProfilList: Array<lookup.ZaehlerProfil>,
    energieartList: Array<lookup.Energeiart>,
}

export namespace LookupLists {
    export const DEFAULT: () => LookupLists = () => ({
        agenturList: [],
        bindungsartList: [],
        brancheList: [],
        funktionList: [],
        kennzeichnungList: [],
        rechtsformList: [],
        selektionList: [],
        kontaktartList: [],
        geschlechtList: [],
        vermittlerList: [],
        customerList: [],
        kontaktBezeichnungList: [],
        adressartList: [],
        quelleList: [],
        staatList: [],
        zaehlerProfilList: [],
        energieartList: [],
    });
}

export interface Bindung {
    kunde?: number;
    zielKunde?: number;
    bindungsart: number;
}

export enum KontaktArt {
    TELEFON = 0,
    EMAIL = 1,
    HOMEPAGE = 2,
    FAX = 256,
    MOBIL = 512,
}

export enum Mandant {
    SMART_DIFFERENT = 'smart_different',
    SMART_INNOVATION = 'smart_innovation',
}

export enum Energieart {
    GRAUSTROM = 1,
    OEKOSTROM = 2,
    GAS = 3,
}

export interface Kontakt {
    id?: number;
    bezeichnung?: string;
    inhalt?: string;
    kontaktartId?: number;
    kunde?: number;
    leascomMandantId?: number;
}

export namespace Kontakt {
    export const DEFAULT = (): Kontakt => ({})

    const PhoneContactTypes = [
        KontaktArt.TELEFON,
        KontaktArt.FAX,
        KontaktArt.MOBIL,
    ]

    const UrlContactTypes = [
        KontaktArt.HOMEPAGE,
    ]

    const EMailContactTypes = [
        KontaktArt.EMAIL,
    ]

    export function isPhoneLike(kart?: KontaktArt): boolean {
        return kart !== undefined && PhoneContactTypes.includes(kart)
    }

    export function isUrl(kart?: KontaktArt): boolean {
        return kart !== undefined && UrlContactTypes.includes(kart)
    }

    export function isEMail(kart?: KontaktArt): boolean {
        return kart !== undefined && EMailContactTypes.includes(kart)
    }

}

export interface Bank {
    id?: number;
    blz?: string;
    bicswift?: string;
}

export namespace Bank {
    export const DEFAULT = (): Bank => ({});
}

export interface Vermittler { id?: { kunde?: number, mandant?: string }, vermittler?: number, untervermittler?: number, }

export interface PageType<T> {
    content: T[];
    empty: boolean;
    first: boolean;
    last: boolean;
    number: number;
    numberOfElements: number;
    pageable: {
        offset: number,
        paged: boolean,
        pageNumber: number,
        pageSize: number,
        sort: { empty: boolean, sorted: boolean, unsorted: boolean },
        unpaged: boolean,
    };
    size: number;
    sort: { empty: boolean, sorted: boolean, unsorted: boolean };
    totalElements: number;
    totalPages: number;
}

export interface Sorter {
    column: string;
    order: SortOrder;
}

export interface Filter {
    column: string;
    keyword: string;
}

export interface Audit {
    id?: number;
    tableName?: string;
    username?: string;
    actionTimestamp?: string;
    actionType?: string;
    oldValues?: { [key: string]: string };
    newValues?: { [key: string]: string };
    updatedCols?: string[];
    query?: string;
}

/**
 * DELETE, INSERT or UPDATE are represented by "d", "i", "u" respectively
 */
export type ActionType = 'd' | 'i' | 'u';

export interface HistoryTableRequestConfig {
    currentPage: number;
    pageSize: number;
    total?: number;
    tableName?: string;
    username?: string;
    actionType?: ActionType;
    start?: string;
    end?: string;
}

export interface CheckHistoryResult {
    result: boolean;
    usernameList: string[];
}

export interface Zaehlerstanderfassung {
    id?: number;
    kunde?: Kunde;
    rechnungsadresse?: KundenAdresse;
    lieferstelle: Lieferstelle;
    netzentgeltList: Array<Netzentgelte>;
    vertragsKostList: Array<VertragsKost>;
    zaehlerstandList: Array<Zaehlerstand>;
    angebot?: AngebotView;
}

export enum Versorgungssituation {
    NEUEINZUG = 'Neueinzug/Vertragspartnerwechsel',
    VERTRAGSWECHSEL = 'Vertragswechsel',
}

export enum VertragspartnerwechselLieferbeginnTyp {
    BALD = 'baldmöglichst',
    Datum = 'am einem bestimmten Datum',
}

export enum KuendungsfristTyp {
    BEREITS = 'bereits gekündigt',
    UNBEKANNT = 'unbekannt',
    FRIST = 'mit Frist',
}

export enum KuendungsfristEinheit {
    TAG = 'Tage',
    WOCHE = 'Wochen',
    MONAT = 'Monate',
}

export interface Lieferstelle {
    id?: number;
    strasse?: string;
    hausnr?: string;
    zusatz?: string;
    plz?: string;
    ort?: string;
    ortsteil?: string;
    land?: string;
    messlokation?: string;
    marktlokation?: string;
    faktor?: number;
    spitze?: number;
    verbrauch?: number;
    profil?: number;
    energieartId?: number;
    versorgungssituation?: Versorgungssituation;
    versorger?: Kunde;
    kundenvertragsnummer?: string;
    kuendungsfristtyp?: KuendungsfristTyp;
    fristanzahl?: number;
    fristeinheit?: KuendungsfristEinheit;
    neueinzuglieferbeginn?: string;
    vertragspartnerwechsellieferbeginntyp?: VertragspartnerwechselLieferbeginnTyp
    vertragspartnerwechsellieferbeginn?: string;
    individuell?: boolean;
    // properties only for gas
    zustandszahl?: number;
    brennwert?: number;
}

export namespace Lieferstelle {
    export const DEFAULT = (): Lieferstelle => ({
        messlokation: 'DE',
        faktor: 1,
        land: 'Deutschland',
        individuell: false,
    });
}

export interface Zaehlerstand {
    id: {
        zaehlerstanderfassungId?: number;
        datum?: string;
    }
    zaehlernummer?: string;
    et?: number;
    ht?: number;
    nt?: number;
}

export namespace Zaehlerstand {
    export const DEFAULT = (): Zaehlerstand => ({
        id: {
            datum: new Date().toISOString().slice(0, 10),
        },
    });
}

export interface VertragsKost {
    id?: number;
    zaehlerstanderfassung?: number;
    grundpreis?: number;
    arbeitspreis?: number;
    htap?: number;
    ntap?: number;
    versorger?: Kunde;
    tarifname?: string;
    preisstand?: string;
    quelle?: number;
    relevant?: boolean;
    gesamtkosten?: number;
    energiekosten?: number;
    energiepreis?: number;
}

export namespace VertragsKost {
    export const DEFAULT = (): VertragsKost => ({
        preisstand: new Date().toISOString().slice(0, 10),
    });
}

export interface Netzentgelte {
    id: {
        zaehlerstanderfassungId?: number;
        preisstand: string;
    },
    grundpreis: number;
    arbeitspreis: number;
    htap?: number;
    ntap?: number;
    messstellenbetrieb: number;
    messung: number;
    abrechnung: number;
    ka?: number;
    kwk?: number;
    nev?: number;
    offshore?: number;
    abschaltbarelasten?: number;
    eeg?: number;
    stromsteuer?: number;
    spitzenpreis?: number;
    fixkosten?: number;
    variablekosten?: number;
    gesamtkosten?: number;
    // gas properties
    konzessionsabgabe?: number;
    bilanzierungsumlage?: number;
    gasspeicherumlage?: number;
    konvertierungsumlage?: number;
    erdgassteuer?: number;
    co2preis?: number;
}

export namespace Netzentgelte {
    export const DEFAULT = (): Netzentgelte => ({
        id: {
            preisstand: new Date().toISOString().slice(0, 10),
        },
        grundpreis: 0,
        arbeitspreis: 0,
        messstellenbetrieb: 0,
        messung: 0,
        abrechnung: 0,
    })
}

export interface ZaehlerstanderfassungView {
    zaehlerstanderfassungId: number;
    kundeId: number;
    kunde: string;
    raddress: string;
    rstrasse: string;
    rhausnr: string;
    rplz: string;
    rort: string;
    lieferstelle: string;
    lstrasse: string;
    lhausnr: string;
    lplz: string;
    lort: string;
    messlokation: string;
    marktlokation: string;
    faktor: number;
    spitze: number;
    verbrauch: number;
    profilId: number;
    profil: string;
    energieartId: number;
    energieart: string;
    versorgungssituation: string;
    kundenvertragsnummer: string;
    kuendungsfristtyp: string;
    fristanzahl: number;
    fristeinheit: string;
    neueinzuglieferbeginn: string;
    vertragspartnerwechsellieferbeginntyp: string;
    vertragspartnerwechsellieferbeginn: string;
    individuell: boolean;
    oversorger: string;
    zaehlernummer: string;
    grundpreis: number;
    arbeitspreis: number;
    htap: number;
    ntap: number;
    tarifname: string;
    preisstand: string;
    quelleId: number;
    datenquelle: string;
    relevant: boolean;
    versorger: string;
    gesamtkosten: number;
    energiekosten: number;
    energiepreis: number;
    lieferbeginn: string;
}

export interface KundenView {
    kundeId: number;
    vorname: string;
    name: string;
    smartDifferent: boolean;
    smartInnovation: boolean;
    kundeVon: string;
    zusatzbezeichnung: string;
    rechtsformId: number;
    rechtsform: string;
    brancheId: number;
    branche: string;
    adresse: string;
    vermittlerId: number;
    vermittler: string;
    untervermittlerId: number;
    untervermittler: string;
    agenturId: number;
    agentur: string;
    kontakt: string;
    score?: number;
    color?: string;
    blz?: string;
    bicswift?: string;
    geburtsdatum?: Date;
}

export interface AngebotView {
    id?: number;
    label?: string;
    durchschnittenergiepreis?: number;
    gesamtverbrauch?: number;
    zaehlerstanderfassungen: Array<ZaehlerstanderfassungView>;
}

export interface Angebot {
    id?: number;
    label?: string;
    durchschnittenergiepreis?: number;
    gesamtverbrauch?: number;
    zaehlerstanderfassungen: Array<Zaehlerstanderfassung>;
}

export namespace Angebot {
    export const DEFAULT: () => AngebotView = () => ({
        zaehlerstanderfassungen: [],
    })
}

export interface AngebotTreeDataType {
    key?: string;
    angebotId?: number;
    label?: string;
    durchschnittenergiepreis?: number;
    gesamtverbrauch?: number;
    zaehlerstanderfassungId?: number;
    kunde?: string;
    raddress?: string;
    rstrasse?: string;
    rhausnr?: string;
    rplz?: string;
    rort?: string;
    lieferstelle?: string;
    lstrasse?: string;
    lhausnr?: string;
    lplz?: string;
    lort?: string;
    messlokation?: string;
    marktlokation?: string;
    faktor?: number;
    spitze?: number;
    verbrauch?: number;
    profilId?: number;
    profil?: string;
    energieartId?: number;
    energieart?: string;
    versorgungssituation?: string;
    kundenvertragsnummer?: string;
    kuendungsfristtyp?: string;
    fristanzahl?: number;
    fristeinheit?: string;
    neueinzuglieferbeginn?: string;
    vertragspartnerwechsellieferbeginntyp?: string;
    vertragspartnerwechsellieferbeginn?: string;
    individuell?: boolean;
    oversorger?: string;
    zaehlernummer?: string;
    grundpreis?: number;
    arbeitspreis?: number;
    htap?: number;
    ntap?: number;
    tarifname?: string;
    preisstand?: string;
    quelleId?: number;
    datenquelle?: string;
    relevant?: boolean;
    versorger?: string;
    gesamtkosten?: number;
    energiekosten?: number;
    energiepreis?: number;
    lieferbeginn?: string;
    children?: AngebotTreeDataType[];
}

export type Address = {
    strasse?: string;
    hausnr?: string;
    plz?: string;
    ort?: string;
    ortsteil?: string;
    land?: string;
}

export type NetzentgeltDTO = {
    kwk: number;
    nev: number;
    offshore: number;
    abschaltbarelasten: number;
    eeg: number;
    stromsteuer: number;
    konzessionsabgabe: number;
    bilanzierungsumlage: number;
    gasspeicherumlage: number;
    konvertierungsumlage: number;
    erdgassteuer: number;
    co2preis: number;
};

export type AppMetadata = {
    "app.profile"?: string
    "app.name"?: string
    "app.version"?: string
}

export namespace AppMetadata {
    export function getProfiles(value: AppMetadata): string[] {
        return value["app.profile"]?.split(",") ?? []
    }
}

export const apiBaseUrl = "/api";
